import Button from "antd/lib/button/button";
import Popconfirm from "antd/lib/popconfirm";
import React, { ReactElement } from "react";
import { useQueryClient } from "react-query";
import { notification } from "antd";
import { globalDeleteItem } from "../../../request/request.thunk";

interface ConfirmDeleteInterface {
  id: string;
  deleteLink: string;
}

export default function OrderCustomConfirmDelete({
  id,
  deleteLink,
}: ConfirmDeleteInterface): ReactElement {
  const queryClient = useQueryClient();

  const confirm = async (): Promise<void> => {
    await globalDeleteItem("/orders/" + id);
    notification.success({
      message: "Order Deleted",
    });
    /**
     * refresh data, this will call the "list" API call
     * by default it will use the deleteLink as query name
     * https://react-query.tanstack.com/guides/query-invalidation
     * TODO, invalidate only the given id, no need to refresh the whole data from the API
     */
    await queryClient.invalidateQueries(deleteLink);
  };

  return (
    <>
      <Popconfirm
        onConfirm={confirm}
        title="Are you sure to delete this order?"
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" danger>
          Delete
        </Button>
      </Popconfirm>
    </>
  );
}
