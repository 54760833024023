import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { useParams } from "react-router";
import { editCategory, getCategory, saveCategory } from "./category.thunks";
import { Field, Form } from "react-final-form";
import { AInput } from "../../form/final-form-antd";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import { CategoryInterface } from "./category.interface";

const CategoryForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation =
    id !== "new" ? useMutation(editCategory) : useMutation(saveCategory);
  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getCategory(id)));
  }
  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={id !== "new" ? "Edit category" : "Create new category"}
      >
        <Form
          onSubmit={(values) => mutation.mutate(values as CategoryInterface)}
          initialValues={data}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                label="Name"
                name="name"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default CategoryForm;
