import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import CanvasForm from "./canvas.form.component";
import CanvasList from "./canvas.list.component";

const Canvas = (): ReactElement => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}/:id`} component={CanvasForm} />
      <Route exact path={path} component={CanvasList} />
    </Switch>
  );
};

export default Canvas;
