import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import { editPage, getPage, savePage } from "./page.thunks";
import { useParams } from "react-router";
import {
  AInput,
  AInputNumber,
  ATextarea,
  AWYSIWYGEditor,
} from "../../form/final-form-antd";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import { Field, Form } from "react-final-form";
import React, { ReactElement } from "react";
import arrayMutators from "final-form-arrays";
import { PageInterface } from "./page.interface";

const PageForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation = id !== "new" ? useMutation(editPage) : useMutation(savePage);
  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getPage(id)));
  }
  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={id !== "new" ? "Edit page" : "Create new page"}
      >
        <Form
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={(values) => mutation.mutate(values as PageInterface)}
          initialValues={data}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div>
                <div>
                  <Field
                    label="Title"
                    name="title"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                  <Field
                    label="Content"
                    name="content"
                    columns={1}
                    rows={4}
                    component={AWYSIWYGEditor}
                    hasFeedback
                    validate={Required}
                  />
                  <Field
                    label="Meta title"
                    name="meta.title"
                    columns={1}
                    component={AInput}
                    hasFeedback
                    validate={Required}
                  />
                  <Field
                    label="Meta Keywords"
                    name="meta.keywords"
                    columns={1}
                    component={AInput}
                    hasFeedback
                  />
                  <Field
                    label="Meta Description"
                    name="meta.description"
                    columns={1}
                    component={ATextarea}
                    rows={4}
                    hasFeedback
                  />
                  <Field
                    label="Meta Other eg: <meta>"
                    name="meta.other"
                    columns={1}
                    component={ATextarea}
                    rows={4}
                    hasFeedback
                  />
                  <Field
                    label="Footer Code <script>"
                    name="meta.footerCode"
                    columns={1}
                    component={AInput}
                    hasFeedback
                  />
                </div>
                {/*<div>*/}
                {/*  <Title level={5}>Page Labels</Title>*/}
                {/*  <Divider />*/}
                {/*  <FieldArray name="labels">*/}
                {/*    {({ fields }) => (*/}
                {/*      <div>*/}
                {/*        <Button*/}
                {/*          type="primary"*/}
                {/*          block*/}
                {/*          icon={<PlusOutlined />}*/}
                {/*          onClick={() => fields.push({ code: "", name: "" })}*/}
                {/*        >*/}
                {/*          Add Labels*/}
                {/*        </Button>*/}
                {/*        {fields.map((name, index) => (*/}
                {/*          <div key={index}>*/}
                {/*            <br />*/}
                {/*            <div className="wrapper-dynamic-rows">*/}
                {/*              <div>*/}
                {/*                <Field*/}
                {/*                  label="Code"*/}
                {/*                  name={`${name}.code`}*/}
                {/*                  columns={1}*/}
                {/*                  component={AInput}*/}
                {/*                  hasFeedback*/}
                {/*                  validate={Required}*/}
                {/*                />*/}
                {/*                <Field*/}
                {/*                  label="Type Label"*/}
                {/*                  name={`${name}.label`}*/}
                {/*                  columns={1}*/}
                {/*                  rows={4}*/}
                {/*                  component={ATextarea}*/}
                {/*                  hasFeedback*/}
                {/*                  validate={Required}*/}
                {/*                />*/}
                {/*              </div>*/}
                {/*              <div>*/}
                {/*                <Button*/}
                {/*                  type="primary"*/}
                {/*                  danger*/}
                {/*                  block*/}
                {/*                  icon={<CloseOutlined />}*/}
                {/*                  onClick={() => fields.remove(index)}*/}
                {/*                >*/}
                {/*                  Remove*/}
                {/*                </Button>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*            <div>*/}
                {/*              <Divider />*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        ))}*/}
                {/*      </div>*/}
                {/*    )}*/}
                {/*  </FieldArray>*/}
                {/*</div>*/}
              </div>
              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default PageForm;
