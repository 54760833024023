import { fetchRequest } from "../../request/request";
import { PanelInterface, PanelPaginationInterface } from "./panel.interface";

const url = "/panels/";

export const savePanel = async (data: PanelInterface): Promise<void> => {
  await fetchRequest(url, data, "post");
};

export const editPanel = async (data: PanelInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put");
};

export const getPanel = async (id: string): Promise<PanelInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const getPanelList = async (data: {
  page: number;
}): Promise<PanelPaginationInterface> => {
  return await fetchRequest(url + "page", data, "post", false);
};
