import { Image, Table } from "antd";
import { useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { tableColumns } from "../../lib/helpers.table";
import { getMediaList } from "./media.thunks";
import { MediaInterface } from "./media.interface";
import { Link } from "react-router-dom";

const MediaList = (): ReactElement => {
  const moduleName = "medias"; // we will use this as both the query name and the delete API endpoint
  const [page, setCurrentPage] = React.useState(0);

  const { data } = useQuery(
    [moduleName, { page }],
    () => getMediaList({ page }),
    { keepPreviousData: true }
  );

  const tableData = data ? data.docs : [];

  const pagination = {
    current: data?.page,
    pageSize: data?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
    total: data?.totalDocs,
    onChange: (page) => {
      pagination.current = page; // updates table pagination
      setCurrentPage(page); // re-fetch the api
    },
  };

  const columnData = [
    {
      title: "File",
      dataIndex: "file",
      sorter: false,
      render: (text, doc) => <Link to={`${moduleName}/` + doc._id}>Home</Link>,
    },
    {
      title: "Media",
      dataIndex: "media",
      sorter: false,
      render: (text, record) => <Image width={200} src={record.image.url} />,
    },
  ];

  const columns = tableColumns(
    columnData,
    { field: "File", url: `${moduleName}/` },
    { url: moduleName }
  );
  return (
    <>
      <DashboardMaster
        showAdd={true}
        title="Medias"
        addUrl={`/dashboard/${moduleName}/new`}
      >
        <Table
          rowKey={(doc: MediaInterface) => doc._id}
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </DashboardMaster>
    </>
  );
};

export default MediaList;
