import React, { ReactElement, useState } from "react";
import Layout, { Footer } from "antd/es/layout/layout";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import Sider from "antd/es/layout/Sider";
import Title from "antd/lib/typography/Title";
import { requestMessage } from "../users/user.selector";
import { useRouter } from "../../hooks/router.hooks";
import { userData } from "./dashboard.selector";
import TopHeader from "./dashboard-header.component";
import { DashboardSidebar } from "./dashboard-sidebar.component";
import { ChangePasswordForm } from "../change-password/change-password.component";
import SettingsForm from "../settings/settings.component";
import Article from "../articles/article.component";
import Canvas from "../canvas/canvas.component";
import Category from "../categories/category.component";
import Media from "../medias/media.component";
import Page from "../pages/page.component";
import NotFound404 from "../errors/404.component";
import Discount from "../discounts/discount.component";
import Panel from "../panels/panel.component";
import Subscriber from "../subscribers/subscriber.component";
import Voucher from "../vouchers/voucher.component";
import Wholesaler from "../wholesalers/wholesaler.component";
import Order from "../orders/order.component";
import GlobalSettings from "../globalsettings/globalsettings.component";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons/lib";
import { Space } from "antd";
import { UserReducerInterface } from "../users/user.reducer";
import Home from "../home/home.component";
import Gallery from "../galleries/gallery.component";

interface SidebarItemInterface {
  path: string;
  component: any;
}

const Dashboard = ({
  userData,
}: {
  userData: UserReducerInterface;
}): ReactElement => {
  const { path } = useRouteMatch();
  const router = useRouter();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleClick = (e) => {
    router.push(`${path}/${e}`);
  };

  if (!userData.isLoggedIn) {
    return <Redirect to="/" />;
  }

  // sidebar items component
  const sidebarItemComponents: Array<SidebarItemInterface> = [
    {
      path: "/home",
      component: <Home />,
    },
    {
      path: "/articles",
      component: <Article />,
    },
    {
      path: "/canvas",
      component: <Canvas />,
    },
    {
      path: "/categories",
      component: <Category />,
    },
    {
      path: "/discounts",
      component: <Discount />,
    },
    {
      path: "/medias",
      component: <Media />,
    },
    {
      path: "/pages",
      component: <Page />,
    },
    {
      path: "/panels",
      component: <Panel />,
    },
    {
      path: "/galleries",
      component: <Gallery />,
    },
    // {
    //   path: "/shippings",
    //   component: <Shipping />,
    // },
    {
      path: "/orders",
      component: <Order />,
    },
    // {
    //   path: "/states",
    //   component: <State />,
    // },
    {
      path: "/subscribers",
      component: <Subscriber />,
    },
    {
      path: "/vouchers",
      component: <Voucher />,
    },
    {
      path: "/wholesalers",
      component: <Wholesaler />,
    },
    {
      path: "/globalsettings",
      component: <GlobalSettings />,
    },
    {
      path: "/globalsettings",
      component: <Wholesaler />,
    },
    {
      path: "/settings/change-password",
      component: <ChangePasswordForm />,
    },
    {
      path: "/settings",
      component: <SettingsForm />,
    },
    {
      path: "/*",
      component: <NotFound404 />,
    },
  ];

  const collapsableStyle = !collapsed
    ? {
        marginBottom: 16,
        marginLeft: 75,
        marginTop: 5,
        color: "white",
        fontSize: 20,
      }
    : { marginBottom: 16, marginLeft: 15, color: "white", fontSize: 20 };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsed={collapsed} width={260}>
        <Space className="logo">
          {!collapsed ? <Title level={4}>Stella Canvas</Title> : null}
          {collapsed ? (
            <MenuUnfoldOutlined
              onClick={toggleCollapsed}
              style={collapsableStyle}
            />
          ) : (
            <MenuFoldOutlined
              onClick={toggleCollapsed}
              style={collapsableStyle}
            />
          )}
        </Space>
        <DashboardSidebar
          isCollapse={collapsed}
          handleClick={(menu) => handleClick(menu)}
        />
      </Sider>

      <Layout className="site-layout">
        <TopHeader />
        <Switch>
          {sidebarItemComponents.map(
            (x: SidebarItemInterface, index: number) => {
              return (
                <Route path={`${path}${x.path}`} key={index}>
                  {x.component}
                </Route>
              );
            }
          )}
        </Switch>
        <Footer style={{ textAlign: "center" }}>
          Stella Canvas © 2022 &mdash; v2
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  errorMessages: requestMessage(state),
  userData: userData(state),
});

export default connect(mapStateToProps, null)(Dashboard);
