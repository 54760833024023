import Button from "antd/lib/button/button";
import React, { ReactElement, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { RequestInterface } from "../interfaces/request.interface";

const SubmitButton = ({
  requestStates,
}: {
  requestStates?: RequestInterface;
}): ReactElement => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current.disabled = requestStates.isLoading;
  }, [requestStates]);

  return (
    <>
      <Button type="primary" htmlType="submit" ref={ref}>
        Submit
      </Button>
    </>
  );
};

const mapStateToProps = (state): { requestStates: RequestInterface } => ({
  requestStates: state.requestReducers,
});

export default connect(mapStateToProps, null)(SubmitButton);
