import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { editCanvas, getCanvas, saveCanvas } from "./canvas.thunks";

import { useParams } from "react-router";
import { Field, Form } from "react-final-form";
import { AInputNumber, ARadioGroup } from "../../form/final-form-antd";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import Radio from "antd/lib/radio";
import { Divider } from "antd";
import Title from "antd/lib/typography/Title";
import { CanvasInterface } from "./canvas.interface";

const CanvasForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation =
    id !== "new" ? useMutation(editCanvas) : useMutation(saveCanvas);
  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getCanvas(id)));
  }

  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={id !== "new" ? "Edit canvas" : "Create new canvas"}
      >
        <Form
          onSubmit={(values) => mutation.mutate(values as CanvasInterface)}
          initialValues={data}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div>
                <Title level={5}>Canvas Size</Title>
                <Divider />
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Canvas Width"
                    name="size.width"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Canvas Height"
                    name="size.height"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Canvas Length"
                    name="size.length"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Canvas Weight"
                    name="size.weight"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div>
                <Title level={5}>Price</Title>
                <Divider />
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Regular Price"
                    name="price.regular"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Discounted Price"
                    name="price.discounted"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Wholesale Price"
                    name="price.wholesale"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Canvas Type"
                    name="type"
                    columns={1}
                    component={ARadioGroup}
                    hasFeedback
                    type="ant-radio"
                    optionType="button"
                    buttonStyle="solid"
                    validate={Required}
                  >
                    <Radio.Button value="Square">Square</Radio.Button>
                    <Radio.Button value="Rectangle">Rectangle</Radio.Button>
                    <Radio.Button value="Long Rectangle">
                      Long Rectangle
                    </Radio.Button>
                    <Radio.Button value="Panorama">Panorama</Radio.Button>
                  </Field>
                </div>
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Sort"
                    name="sort"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>

              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default CanvasForm;
