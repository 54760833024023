import { Space, Table } from "antd";
import { useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { tableColumns } from "../../lib/helpers.table";
import { ArticleInterface } from "../articles/article.interface";
import { getCanvasList } from "./canvas.thunks";
import { Link } from "react-router-dom";

const CanvasList = (): ReactElement => {
  const moduleName = "canvas"; // we will use this as both the query name and the delete API endpoint
  const [page, setCurrentPage] = React.useState(0);

  const { data } = useQuery(
    [moduleName, { page }],
    () => getCanvasList({ page }),
    { keepPreviousData: true }
  );

  const tableData = data ? data.docs : [];

  const pagination = {
    current: data?.page,
    pageSize: data?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
    total: data?.totalDocs,
    onChange: (page) => {
      pagination.current = page; // updates table pagination
      setCurrentPage(page); // re-fetch the api
    },
  };

  const columnData = [
    {
      title: "Size",
      dataIndex: "size",
      render: (text, doc) => (
        <Link to={`${moduleName}/` + doc._id}>
          {doc?.size?.height + "x" + doc?.size?.width}
        </Link>
      ),
    },
    {
      title: "Original Price",
      dataIndex: "price",
      sorter: (a, b) => {
        return a.price?.regular
          .toString()
          .localeCompare(b.price?.regular.toString());
      },
      render: (text, doc) => <Space size="middle">{doc?.price?.regular}</Space>,
    },
    {
      title: "Discount Price",
      dataIndex: "price",
      sorter: (a, b) => {
        return a.price?.discounted
          .toString()
          .localeCompare(b.price?.discounted.toString());
      },
      render: (text, doc) => (
        <Space size="middle">{doc?.price?.discounted}</Space>
      ),
    },
    {
      title: "Wholesale Price",
      dataIndex: "price",
      sorter: (a, b) => {
        return a.price?.wholesale
          .toString()
          .localeCompare(b.price?.wholesale.toString());
      },
      render: (text, doc) => (
        <Space size="middle">{doc?.price?.wholesale}</Space>
      ),
    },
    "Type",
    "Sort",
  ];

  const columns = tableColumns(
    columnData,
    { field: "", url: `${moduleName}/` },
    { url: moduleName }
  );
  return (
    <>
      <DashboardMaster
        showAdd={true}
        title="Canvas"
        addUrl={`/dashboard/${moduleName}/new`}
      >
        <Table
          rowKey={(doc: ArticleInterface) => doc._id}
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </DashboardMaster>
    </>
  );
};

export default CanvasList;
