import React, { ReactElement } from "react";
import "./App.less";
import { hot } from "react-hot-loader";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard.component";

import { Login } from "./components/users/login.component";
import NotFound404 from "./components/errors/404.component";
import otherError from "./components/errors/other-errors.component";
import FacebookCallback from "./components/social-callbacks/facebook-callback.component";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

const App = (): ReactElement => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/facebook-callback" component={FacebookCallback} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/error" component={otherError} />
        <Route path="*">
          <NotFound404 />
        </Route>
      </Switch>
    </Router>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

export default hot(module)(App);
