import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { useParams } from "react-router";
import { editDiscount, getDiscount, saveDiscount } from "./discount.thunks";
import { Field, Form } from "react-final-form";
import { AInput, AInputNumber, ARadioGroup } from "../../form/final-form-antd";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import Radio from "antd/lib/radio";
import { DiscountInterface } from "./discount.interface";

const DiscountForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation =
    id !== "new" ? useMutation(editDiscount) : useMutation(saveDiscount);
  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getDiscount(id)));
  }
  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={id !== "new" ? "Edit discount" : "Create new discount"}
      >
        <Form
          onSubmit={(values) => mutation.mutate(values as DiscountInterface)}
          initialValues={data}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                label="Code"
                name="code"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Value"
                name="value"
                columns={1}
                style={{ width: "100%" }}
                component={AInputNumber}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Minimum Item"
                name="minItem"
                columns={1}
                style={{ width: "100%" }}
                component={AInputNumber}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Max Usage"
                name="maxUse"
                columns={1}
                style={{ width: "100%" }}
                component={AInputNumber}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Type"
                name="type"
                columns={1}
                component={ARadioGroup}
                hasFeedback
                type="ant-radio"
                optionType="button"
                buttonStyle="solid"
                validate={Required}
              >
                <Radio.Button value="percentage">Percentage</Radio.Button>
                <Radio.Button value="fixed">Fixed</Radio.Button>
              </Field>
              <Field
                label="Kind"
                name="kind"
                columns={1}
                component={ARadioGroup}
                hasFeedback
                type="ant-radio"
                optionType="button"
                buttonStyle="solid"
                validate={Required}
              >
                <Radio.Button value="discount">Discount</Radio.Button>
                <Radio.Button value="voucher">Voucher</Radio.Button>
              </Field>
              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default DiscountForm;
