import { fetchRequest } from "../../request/request";
import { MediaInterface, MediaPaginationInterface } from "./media.interface";

const url = "/medias/";

export const getMediaList = async (data: {
  page: number;
}): Promise<MediaPaginationInterface> => {
  return await fetchRequest(url + "page", data, "post", false);
};

export const getMedia = async (id: string): Promise<MediaInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const editMedia = async (data: MediaInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put");
};

export const saveMedia = async (data: MediaInterface): Promise<void> => {
  await fetchRequest(url, data, "post");
};
