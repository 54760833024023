import React, { ReactElement } from "react";
import { Field, Form } from "react-final-form";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import { AInput } from "../../form/final-form-antd";
import SubmitButton from "../../global-components/submit-button.component";
import { Required } from "../../form/validations";
import { useMutation, useQuery } from "react-query";
import { loadSettings, saveSettings } from "./settings.thunks";

// eslint-disable-next-line no-shadow
const SettingsForm = (): ReactElement => {
  const mutation = useMutation(saveSettings);
  const { data } = useQuery("settings", loadSettings);

  return (
    <>
      <DashboardMaster showAdd={false} title="Settings">
        <Form
          onSubmit={(values) => mutation.mutate(values)}
          initialValues={data}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                label="First Name"
                name="firstName"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Last Name"
                name="lastName"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <div className="wrapper-one-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default SettingsForm;
