import { LOGOUT, SET_TOKEN } from "./user.actions";

export interface UserReducerInterface {
  isLoggedIn: boolean;
  userData: any;
}

const initialState: UserReducerInterface = {
  isLoggedIn: false,
  userData: null,
};

const userReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TOKEN: {
      const { userData } = payload;
      return {
        ...state,
        isLoggedIn: true,
        userData,
      };
    }
    case LOGOUT: {
      const { userData } = payload;
      return {
        ...state,
        isLoggedIn: false,
        userData,
      };
    }

    default:
      return state;
  }
};

export default userReducers;
