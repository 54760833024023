import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import GalleryForm from "./gallery.form.component";
import GalleryList from "./gallery.list.component";

const Gallery = (): ReactElement => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/:id`} component={GalleryForm} />
      <Route exact path={path} component={GalleryList} />
    </Switch>
  );
};

export default Gallery;
