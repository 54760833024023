import { fetchRequest } from "../../request/request";
import moment from "moment";

const url = "/orders/";

export const getStatistics = async (moment: {
  year: moment.Moment;
}): Promise<{
  lineGraph: Array<{ month: string; totalPrice: number }>;
  pieChart: Array<{ type: string; value: number }>;
}> => {
  const lineGraph = [];
  const pieChart = [];

  for (let i = 1; i < 13; i++) {
    lineGraph.push({
      month: Intl.DateTimeFormat("en", { month: "long" }).format(
        new Date(i.toString())
      ),
      totalPrice: 0,
    });
  }
  const result = await fetchRequest(
    url + "statistics/" + moment.year.format("YYYY"),
    null,
    "get",
    false
  );

  result.lineGraph.forEach((x) => {
    const r = lineGraph.find(
      (d) =>
        d.month ===
        Intl.DateTimeFormat("en", { month: "long" }).format(
          new Date(x._id.month.toString())
        )
    );
    r.totalPrice = x.totalPrice;
  });

  result.pieChart.forEach((x) => {
    pieChart.push({ type: x._id.status, value: x.count });
  });
  return {
    lineGraph,
    pieChart,
  };
};
