import { Alert, Image, Space } from "antd";
import Text from "antd/es/typography/Text";
import React, { ReactElement } from "react";

const apiHost = process.env.REACT_APP_API;

export const OrderCanvasDetailBox = (props: { order: any }): ReactElement => {
  let borderColor: string;
  let size: string;

  const { order: x } = props;

  if (
    ["colour", "wrap"].includes(x.data.options?.effect) &&
    x.type === "canvas"
  ) {
    borderColor = ` (${x.data.options?.borderColor})`;
  }

  if (["canvas", "collages"].includes(x.type)) {
    size = x.data.options?.isPortrait
      ? `${x.data.item.size.height} x ${x.data.item.size.width}`
      : `${x.data.item.size.width} x ${x.data.item.size.height}`;
  } else {
    size = `${x.data.item.size.width} x ${x.data.item.size.height}`;
  }

  return (
    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Image width={200} src={`${apiHost}/uploads/rendered/${x._id}.png`} />
        <Alert
          message={
            <code>
              <Text style={{ fontSize: "12px" }}>Qty: {x.data.qty}</Text>
              <br />
              <Text style={{ fontSize: "12px" }}>
                Size: {x.type} {size}
              </Text>
              <br />
              <Text style={{ fontSize: "12px" }}>
                Border: {x.type === "canvas" ? x.data.options?.effect : "--"}
                {borderColor}
              </Text>
              <br />
              <Text style={{ fontSize: "12px" }}>
                Finishing:{" "}
                {x.type === "canvas" ? x.data.options?.finishing : "--"}
              </Text>
              <br />
              <Text style={{ fontSize: "12px" }}>
                Retouch:{" "}
                {x.type === "canvas" ? x.data.options?.retouching : "--"}
              </Text>
              <br />
              <Text style={{ fontSize: "12px" }}>
                Frame:{" "}
                {x.type === "canvas" ? x.data.options?.frameBorders : "--"}
              </Text>
              <br />
              {x.type === "canvas" && x.data.options?.retouching !== "none" ? (
                <Text style={{ fontSize: "12px", color: "red" }}>
                  Addons:{" "}
                  {x.data.options.minorEdits.length > 0
                    ? x.data.options.minorEdits.join()
                    : x.data.options.majorEdits}
                </Text>
              ) : null}
            </code>
          }
          type="info"
        />
      </Space>
    </div>
  );
};
