/**
 * Holds global status of the request
 * @type {string}
 */

interface RequestPayloadInterface {
  title?: string;
  message: string;
}

interface RequestTypeInterface {
  type: string;
  payload?: RequestPayloadInterface;
}

export const REQUEST_IN_PROGRESS = "REQUEST_IN_PROGRESS";
export const requestInProgress = (): RequestTypeInterface => ({
  type: REQUEST_IN_PROGRESS,
});

export const CLOSE_REQUEST_ERROR = "CLOSE_REQUEST_ERROR";
export const closeRequestError = (): RequestTypeInterface => ({
  type: CLOSE_REQUEST_ERROR,
});

export const REQUEST_FAILURE = "REQUEST_FAILURE";
export const requestFailure = (): RequestTypeInterface => ({
  type: REQUEST_FAILURE,
  payload: {
    title: "Request Error",
    message: "Error while requesting data",
  },
});

export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const validationError = (message: string): RequestTypeInterface => ({
  type: VALIDATION_ERROR,
  payload: {
    title: "Error",
    message,
  },
});

export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const requestSuccess = (): RequestTypeInterface => ({
  type: REQUEST_SUCCESS,
});

export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const saveSuccess = (
  message: string | null = "Saved"
): RequestTypeInterface => ({
  type: SAVE_SUCCESS,
  payload: {
    message,
  },
});

export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const deleteSuccess = (): RequestTypeInterface => ({
  type: DELETE_SUCCESS,
});
