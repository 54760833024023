import React, { ReactElement } from "react";
import { Field, Form } from "react-final-form";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import { APassword } from "../../form/final-form-antd";
import SubmitButton from "../../global-components/submit-button.component";
import { Required } from "../../form/validations";
import { useMutation } from "react-query";
import { changePassword } from "./change-password.thunks";

export interface ChangePasswordInterface {
  oldPassword: string;
  password: string;
  repeatPassword: string;
}

export const ChangePasswordForm = (): ReactElement => {
  const mutation = useMutation(changePassword);

  return (
    <>
      <DashboardMaster showAdd={false} title="Change Password">
        <Form
          onSubmit={(values) =>
            mutation.mutate(values as ChangePasswordInterface)
          }
          initialValues={null}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                label="Current Password"
                name="oldPassword"
                columns={1}
                component={APassword}
                hasFeedback
                validate={Required}
              />
              <Field
                label="New Password"
                name="password"
                columns={1}
                component={APassword}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Repeat Password"
                name="repeatPassword"
                columns={1}
                component={APassword}
                hasFeedback
                validate={Required}
              />
              <div className="wrapper-one-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
