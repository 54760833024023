import React, { ReactElement, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getOrder, moveOrder } from "./order.thunks";
import { Link, useLocation } from "react-router-dom";
import { OrderInterface } from "./order.interface";
import { Alert, Col, DatePicker, Row, Space, Table, Tag } from "antd";
import { tableColumns } from "../../lib/helpers.table";
import OrderCustomDashboardComponent from "./order.custom.dashboard.component";
import Button from "antd/lib/button/button";
import { defaultDateFormat } from "../../lib/date";
import { useRouteMatch } from "react-router";
import { SwapOutlined } from "@ant-design/icons/lib";
import { OrderMoveDropDown } from "./components/OrderMoveDropdown";
import Search from "antd/es/input/Search";
import { OrderStatus } from "./components/OrderStatus";
import { OrderCanvasDetailBox } from "./components/OrderCanvasDetailBox";
import { DeliveryDateStatus } from "./components/DeliveryStatus";
import { DeliveryTracking } from "./components/DeliveryTracking";
import OrderCustomConfirmDelete from "./components/OrderCustomConfirmDelete";

const OrderList = (): ReactElement => {
  const queryClient = useQueryClient();
  const [orderStatus, setOrderStatus] = useState("new");
  const [key, setSearchParams] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [candidateOrdersToMove, setCandidateOrdersToMove] = useState([]);
  const { path } = useRouteMatch();
  const [page, setCurrentPage] = React.useState(0);

  //get current path or status
  const location = useLocation();
  const currentPath = location.pathname.split("/")[3];

  const { data } = useQuery(
    [currentPath, { page, key, deliveryDate }],
    () => getOrder({ page, key, deliveryDate }, currentPath),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );

  // search order
  const onSearchClick = (key: string) => {
    setSearchParams(key);
  };

  const tableData = data ? data.docs : [];

  const pagination = {
    current: data?.page,
    pageSize: data?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
    total: data?.totalDocs,
    onChange: (page) => {
      pagination.current = page; // updates table pagination
      setCurrentPage(page); // re-fetch the api
    },
  };

  // row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows) => {
      setCandidateOrdersToMove(selectedRows.map((x) => x._id));
    },
  };

  // move order(s) based from status selected
  const onMoveButtonClick = async (): Promise<void> => {
    await moveOrder({ ids: candidateOrdersToMove, status: orderStatus });
    setCandidateOrdersToMove([]);
    await queryClient.invalidateQueries(currentPath);
  };

  const columnData = [
    {
      title: "Name",
      dataIndex: "orderData?.delivery?.firstName",
      align: "top",
      sorter: (a, b) => {
        return a.orderData.delivery.firstName?.localeCompare(
          b.orderData.delivery.firstName
        );
      },
      render: (text, doc) => (
        <>
          <Link to={`${path}/` + doc._id}>
            Company Name: {doc?.orderData?.delivery?.companyName || "--"} <br />
            Fullname: {doc?.orderData?.delivery?.firstName}{" "}
            {doc?.orderData?.delivery?.lastName}
          </Link>

          {doc?.orderData?.delivery?.note !== "" ? (
            <div style={{ marginTop: "20px" }}>
              <Alert
                message={doc?.orderData?.delivery?.note}
                type="warning"
                showIcon
              />
            </div>
          ) : null}
        </>
      ),
      width: "10%",
    },
    {
      title: "Canvas Data",
      dataIndex: "orderData",
      sorter: (a, b) => {
        return a.orderData.delivery.email?.localeCompare(
          b.orderData.delivery.email
        );
      },
      render: (text, doc) => (
        <>
          {doc?.orderData?.orderData.map((item: any, index: number) => {
            return <OrderCanvasDetailBox key={index} order={item} />;
          })}
        </>
      ),
      width: "50%",
    },
    {
      title: "Delivery And Email",
      dataIndex: "orderData.delivery.email",
      sorter: (a, b) => {
        return a.orderData.delivery.email?.localeCompare(
          b.orderData.delivery.email
        );
      },
      render: (text, doc) => (
        <>
          Pick Up:{" "}
          {doc?.orderData?.order?.pickup ? (
            <Tag color="green">True</Tag>
          ) : (
            <Tag color="red">False</Tag>
          )}{" "}
          <br />
          Email: {doc?.orderData?.delivery?.email} <br />
          Delivery Detail: {doc?.orderData?.delivery?.streetAddress}
        </>
      ),
    },
    currentPath === "printed"
      ? {
          title: "Tracking Number/Courier",
          dataIndex: "courier",
          sorter: (a, b) => {
            return a.courier?.localeCompare(b.courier);
          },
          render: (text, doc) => <DeliveryTracking data={doc} />,
        }
      : {
          title: "Delivery Date",
          dataIndex: "delivery",
          sorter: (a, b) => {
            return a.delivery?.localeCompare(b.delivery);
          },
          render: (text, doc) => <DeliveryDateStatus data={doc} />,
        },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, doc) => <OrderStatus status={doc?.status} />,
    },
    {
      title: "Transaction Details",
      dataIndex: "paypalData",
      sorter: (a, b) => {
        return a.paypalData?.create_time.localeCompare(
          b.paypalData?.create_time
        );
      },
      render: (text, doc) => defaultDateFormat(doc.paypalData?.create_time),
    },
    {
      key: "delete",
      dataIndex: "_id",
      fixed: "left",
      sorter: false,
      render: (_id) => (
        <>
          <OrderCustomConfirmDelete deleteLink={currentPath} id={_id} />
        </>
      ),
    },
  ];

  const columns = tableColumns(columnData, {
    field: "Title",
    url: `${currentPath}/`,
  });

  return (
    <>
      <Row justify={"end"}>
        <Col span={8}>
          <Space>
            <OrderMoveDropDown handleChange={(data) => setOrderStatus(data)} />
            <Button
              type="primary"
              icon={<SwapOutlined />}
              onClick={onMoveButtonClick}
              disabled={candidateOrdersToMove.length === 0}
            >
              Move ({orderStatus})
            </Button>
          </Space>
        </Col>
        <Col span={8}>
          <Search
            style={{ width: "100%" }}
            placeholder="Search by name, email or transaction id"
            allowClear
            enterButton="Search"
            onSearch={onSearchClick}
          />
        </Col>
        <Col span={6} offset={2}>
          <DatePicker
            style={{ width: "100%" }}
            allowClear
            placeholder={"Select Delivery Date"}
            onChange={(date) =>
              date !== null
                ? setDeliveryDate(date.format("MM-DD-YYYY"))
                : setDeliveryDate("")
            }
          />
        </Col>
      </Row>

      <OrderCustomDashboardComponent>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey={(doc: OrderInterface) => doc._id}
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </OrderCustomDashboardComponent>
    </>
  );
};

export default OrderList;
