import { Button, Card, Col, Descriptions, Image, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib";
import React, { ReactElement } from "react";

const apiHost = process.env.REACT_APP_API;

export const OrderDetailCanvasData = (props: {
  order: any;
  orderObject: any;
}): ReactElement => {
  const { order, orderObject } = props;
  let borderColor: string;
  let size: string;

  if (
    ["colour", "wrap"].includes(order.data.options?.effect) &&
    order.type === "canvas"
  ) {
    borderColor = ` (${order.data.options?.borderColor})`;
  }

  if (["canvas", "collages"].includes(order.type)) {
    size = order.data.options?.isPortrait
      ? `${order.data.item.size.height} x ${order.data.item.size.width}`
      : `${order.data.item.size.width} x ${order.data.item.size.height}`;
  } else {
    size = `${order.data.item.size.width} x ${order.data.item.size.height}`;
  }

  return (
    <Card style={{ margin: "5px" }}>
      <Row gutter={[24, 16]}>
        <Col span={12}>
          <Image
            width={400}
            src={`${apiHost}/uploads/rendered/${order._id}.png`}
          />
        </Col>
        <Col span={12}>
          <Descriptions
            column={1}
            extra={
              <Button
                type="primary"
                danger
                icon={<DownloadOutlined />}
                href={`${apiHost}/api/public/order/download/raw/${order._id}`}
              >
                Download Original Image
              </Button>
            }
            title={"Canvas Detail"}
            bordered
            size="small"
          >
            <Descriptions.Item label="Name">
              {orderObject.type} {size}
            </Descriptions.Item>
            <Descriptions.Item label="Qty">{order.data.qty}</Descriptions.Item>
            <Descriptions.Item label="Border">
              {order.type === "canvas" ? order.data.options?.effect : "--"}
              {borderColor}
            </Descriptions.Item>
            <Descriptions.Item label="Finishing">
              {order.type === "canvas" ? order.data.options?.finishing : "--"}
            </Descriptions.Item>
            <Descriptions.Item label="Retouch">
              {order.type === "canvas" ? order.data.options?.retouching : "--"}
            </Descriptions.Item>
            <Descriptions.Item label="Frame">
              {order.type === "canvas"
                ? order.data.options?.frameBorders
                : "--"}
            </Descriptions.Item>
            {order.type === "canvas" ? (
              <Descriptions.Item label="Add Ons">
                Addons:{" "}
                {order.data.options.minorEdits.length > 0 &&
                order.data.options?.retouching !== "none"
                  ? order.data.options.minorEdits.join()
                  : order.data.options.majorEdits}
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </Col>
      </Row>
    </Card>
  );
};
