import { Table } from "antd";
import { useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { getVouchersList } from "./voucher.thunks";
import { tableColumns } from "../../lib/helpers.table";
import { VoucherInterface } from "./voucher.interface";
import Text from "antd/es/typography/Text";

const VoucherList = (): ReactElement => {
  const moduleName = "vouchers"; // we will use this as both the query name and the delete API endpoint
  const [page, setCurrentPage] = React.useState(0);

  const { data } = useQuery(
    [moduleName, { page }],
    () => getVouchersList({ page }),
    { keepPreviousData: true }
  );

  const tableData = data ? data.docs : [];

  const pagination = {
    current: data?.page,
    pageSize: data?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
    total: data?.totalDocs,
    onChange: (page) => {
      pagination.current = page; // updates table pagination
      setCurrentPage(page); // re-fetch the api
    },
  };

  const columnData = [
    {
      title: "Code",
      dataIndex: "number",
      sorter: (a, b) => {
        return a.number.localeCompare(b.number);
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Paid",
      dataIndex: "isPaid",
      sorter: (a, b) => a.isPaid - b.isPaid,
      render: (text, doc) => <Text>{doc?.isPaid.toString()}</Text>,
    },
  ];

  const columns = tableColumns(
    columnData,
    { field: "Title", url: `${moduleName}/` },
    { url: moduleName }
  );
  return (
    <>
      <DashboardMaster title="Vouchers" showAdd={false}>
        <Table
          rowKey={(doc: VoucherInterface) => doc._id}
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </DashboardMaster>
    </>
  );
};

export default VoucherList;
