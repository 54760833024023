import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { editArticle, getArticle, saveArticle } from "./article.thunks";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router";
import { getAllCategory } from "../categories/category.thunks";
import {
  AInput,
  ASelect,
  ATextarea,
  AWYSIWYGEditor,
} from "../../form/final-form-antd";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import { ArticleInterface } from "./article.interface";

const ArticleForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation =
    id !== "new" ? useMutation(editArticle) : useMutation(saveArticle);
  const { data: categories } = useQuery([], getAllCategory);
  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getArticle(id)));
  }
  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={id !== "new" ? "Edit article" : "Create new article"}
      >
        <Form
          onSubmit={(values) => mutation.mutate(values as ArticleInterface)}
          initialValues={data}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                label="Title"
                name="title"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Category"
                name="category"
                columns={1}
                component={ASelect}
                hasFeedback
                options={categories}
                validate={Required}
              />
              <Field
                label="Content"
                name="content"
                columns={1}
                component={AWYSIWYGEditor}
                rows={4}
                hasFeedback
                validate={Required}
              />

              <Field
                label="Excerpt"
                name="excerpt"
                columns={1}
                component={ATextarea}
                hasFeedback
                rows={4}
                validate={Required}
              />
              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default ArticleForm;
