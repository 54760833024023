import { Image } from "antd";
import React, { ReactElement } from "react";

export const FormImagePreview = (props: { url }): ReactElement => {
  console.log(props.url);
  return (
    <div>
      <div className="wrapper-one-column">
        <div />
        <Image width={200} src={props.url} />
      </div>
      <br />
    </div>
  );
};
