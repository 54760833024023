import { Tag } from "antd";
import React, { ReactElement } from "react";

export const OrderStatus = (props: { status: string }): ReactElement => {
  const colorCategory: Array<{ value: string; color: string }> = [
    {
      value: "failed",
      color: "red",
    },
    {
      value: "created",
      color: "geekblue",
    },
    {
      value: "approved",
      color: "green",
    },
    {
      value: "cancelled",
      color: "red",
    },
    {
      value: "in process",
      color: "gold",
    },
    {
      value: "expired",
      color: "orange",
    },
  ];
  const currentColor = colorCategory.find((x) => x.value === props.status);
  return <Tag color={currentColor.color}>{props.status}</Tag>;
};
