export const SET_TOKEN = "SET_TOKEN";
export const setToken = (userData) => ({
  type: "SET_TOKEN",
  payload: { userData },
});

export const LOGOUT = "LOGOUT";
export const logout = () => ({
  type: "LOGOUT",
  payload: { userData: null },
});
