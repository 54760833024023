import { useMutation, useQuery } from "react-query";
import React, { ReactElement } from "react";
import { getGlobalSetting, saveGlobalSetting } from "./globalsettings.thunks";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import { Field, Form } from "react-final-form";
import { Button, Col, Divider } from "antd";
import {
  ADatePicker,
  AInput,
  AInputNumber,
  AInputPercentage,
  APostalInput,
  ASwitch,
  ATextarea,
} from "../../form/final-form-antd";
import SubmitButton from "../../global-components/submit-button.component";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { GlobalSettingsInterface } from "./globalsettings.interface";
import { Required } from "../../form/validations";
import { FieldArray } from "react-final-form-arrays";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons/lib";
import arrayMutators from "final-form-arrays";
import Row from "antd/lib/grid/row";

const GlobalSettingsForm = (): ReactElement => {
  const mutation = useMutation(saveGlobalSetting);
  const { data } = useQuery("", getGlobalSetting);

  const formatDate = (date) => {
    return moment(date).format("DD MMM YYYY");
  };
  return (
    <>
      <DashboardMaster showAdd={false} title="Global Settings">
        <Form
          mutators={{
            ...arrayMutators,
            allowUploads: (args, state, utils) => {
              utils.changeValue(state, "allowUploads", () => 1);
              console.log(state);
            },
          }}
          onSubmit={(values) =>
            mutation.mutate(values as GlobalSettingsInterface)
          }
          initialValues={data}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Title level={5}>Prices</Title>
              <Divider />
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Price per KG"
                    name="pricePerKg"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                  />
                </div>
                <div>
                  <Field
                    label="Additional fix price"
                    name="additionalPrice"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                  />
                </div>
                <div>
                  <Field
                    label="Price per Gallery Image"
                    name="galleryPricePerImage"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                  />
                </div>
              </div>
              <Title level={5}>Max weight</Title>
              <Divider />
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Max Weight"
                    name="maxWeight"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                  />
                </div>
              </div>
              <FieldArray name="postalCharges">
                {({ fields }) => (
                  <div>
                    <div>
                      <Row align="middle" justify="space-between">
                        <Title level={5}>Postal Charges</Title>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() =>
                            fields.push({ from: 0, to: 0, percentage: 0 })
                          }
                        >
                          Add Charges
                        </Button>
                      </Row>
                    </div>
                    <Divider />
                    {fields.map((name, index) => (
                      <div key={index}>
                        <br />
                        <Row justify="start">
                          <Col className="gutter-row" span={4}>
                            <Field
                              label="From"
                              name={`${name}.from`}
                              columns={2}
                              max={100}
                              component={APostalInput}
                              hasFeedback
                            />
                          </Col>
                          <Col className="gutter-row" span={4}>
                            <Field
                              label="To"
                              name={`${name}.to`}
                              columns={2}
                              component={APostalInput}
                              hasFeedback
                            />
                          </Col>
                          <Col className="gutter-row" span={12}>
                            <Row justify="start">
                              <Col className="gutter-row" span={12}>
                                <Field
                                  label="Additional"
                                  name={`${name}.percentage`}
                                  columns={2}
                                  component={AInputPercentage}
                                  hasFeedback
                                />
                              </Col>
                              <Col
                                className="gutter-row"
                                span={6}
                                style={{ marginLeft: "-50px" }}
                              >
                                <Button
                                  type="primary"
                                  danger
                                  block
                                  icon={<CloseOutlined />}
                                  onClick={() => fields.remove(index)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Divider />
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <Divider />
              <Title level={5}>Allow Uploads</Title>
              <Divider />
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Allow"
                    name="allowUploads"
                    columns={2}
                    component={ASwitch}
                    hasFeedback
                    checkLabel="Yes"
                    unCheckLabel="No"
                  />
                  <Field
                    label="Text"
                    name="allowUploadsText"
                    columns={2}
                    component={AInput}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <Title level={5}>Border Frame Options</Title>
              <Divider />
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Hide All Border Frames"
                    name="hideAllBorderFrames"
                    columns={2}
                    component={ASwitch}
                    hasFeedback
                    checkLabel="On"
                    unCheckLabel="Off"
                  />
                  <Field
                    label="Show 20mm Frame"
                    name="show20mmFrame"
                    columns={2}
                    component={ASwitch}
                    hasFeedback
                    checkLabel="On"
                    unCheckLabel="Off"
                  />
                  <Field
                    label="Show 30mm Frame"
                    name="show30mmFrame"
                    columns={2}
                    component={ASwitch}
                    hasFeedback
                    checkLabel="On"
                    unCheckLabel="Off"
                  />
                  <Field
                    label="Show 38mm Frame"
                    name="show38mmFrame"
                    columns={2}
                    component={ASwitch}
                    hasFeedback
                    checkLabel="On"
                    unCheckLabel="Off"
                  />
                </div>
              </div>
              <Title level={5}>Production Time Option</Title>
              <Divider />
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Express?"
                    name="expressProduction"
                    columns={2}
                    component={ASwitch}
                    hasFeedback
                    checkLabel="On"
                    unCheckLabel="Off"
                  />
                </div>
              </div>
              {/*<Title level={5}>Delivery Option</Title>*/}
              {/*<Divider />*/}
              {/*<div className="wrapper-half">*/}
              {/*  <div>*/}
              {/*    <Field*/}
              {/*      label="Free delivery"*/}
              {/*      name="freeDelivery"*/}
              {/*      columns={2}*/}
              {/*      component={ASwitch}*/}
              {/*      hasFeedback*/}
              {/*      initialValue={true}*/}
              {/*      checkLabel="Yes"*/}
              {/*      unCheckLabel="No"*/}
              {/*    />*/}
              {/*    <Field*/}
              {/*      label="Free Delivery Minimum"*/}
              {/*      name="freeDeliveryMin"*/}
              {/*      columns={2}*/}
              {/*      style={{ width: "100%" }}*/}
              {/*      component={AInputNumber}*/}
              {/*      hasFeedback*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
              <Title level={5}>Website Pop-up Message</Title>
              <Divider />
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Show Pop-up Message"
                    name="showModal"
                    columns={2}
                    component={ASwitch}
                    hasFeedback
                    checkLabel="Yes"
                    unCheckLabel="No"
                  />
                  <Field
                    label="Pop-up Message"
                    name="modalMessage.message"
                    columns={2}
                    component={ATextarea}
                    rows={4}
                    hasFeedback
                  />
                  <Title level={5}>{`Show Pop-up from ${formatDate(
                    values["modalMessage"]?.startDate
                  )} to 
                                        ${formatDate(
                                          values["modalMessage"]?.endDate
                                        )}
                                        `}</Title>
                  <Divider />
                  <Field
                    label="Start Date"
                    name="modalMessage.startDate"
                    columns={2}
                    component={ADatePicker}
                    hasFeedback
                  />
                  <Field
                    label="End Date"
                    name="modalMessage.endDate"
                    columns={2}
                    component={ADatePicker}
                    hasFeedback
                  />
                </div>
              </div>
              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default GlobalSettingsForm;
