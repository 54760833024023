import { fetchRequest } from "../../request/request";
import { PageInterface, PagePaginationInterface } from "./page.interface";

const url = "/pages/";

export const savePage = async (data: PageInterface): Promise<void> => {
  await fetchRequest(url, data, "post", true);
};

export const editPage = async (data: PageInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put", true);
};

export const getPage = async (id: string): Promise<PageInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const getPageList = async (data: {
  page: number;
}): Promise<PagePaginationInterface> => {
  return await fetchRequest(url + "/page", data, "post", false);
};
