import { fetchRequest } from "../../request/request";
import { MediaPaginationInterface } from "../medias/media.interface";
import { GalleryInterface } from "./gallery.interface";

const url = "/galleries/";

export const getGalleryList = async (data: {
  page: number;
}): Promise<MediaPaginationInterface> => {
  return await fetchRequest(url + "page", data, "post", false);
};

export const getGallery = async (id: string): Promise<GalleryInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const editGallery = async (data: GalleryInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put");
};

export const saveGallery = async (data: GalleryInterface): Promise<void> => {
  const params = {
    ...data,
    localUrl: `/${data.image.upload}`,
  };
  await fetchRequest(url, params, "post");
};
