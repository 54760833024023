import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import CategoryForm from "./category.form.component";
import CategoryList from "./category.list.component";

const Category = (): ReactElement => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/:id`} component={CategoryForm} />
      <Route exact path={path} component={CategoryList} />
    </Switch>
  );
};

export default Category;
