import { fetchRequest } from "../../request/request";
import { VoucherPaginationInterface } from "./voucher.interface";

const url = "/vouchers/";

export const getVouchersList = async (data: {
  page: number;
}): Promise<VoucherPaginationInterface> => {
  return await fetchRequest(url + "page", data, "post", false);
};
