import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";

import { useParams } from "react-router";
import { Field, Form } from "react-final-form";
import { AInput, ATextarea } from "../../form/final-form-antd";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import { Card } from "antd";
import { editOrder, getOrderDetail } from "./order.thunks";
import { OrderInvoiceDescription } from "./components/OrderInvoiceDescription";
import { OrderDetailCanvasData } from "./components/OrderDetailCanvasData";
import { OrderInterface } from "./order.interface";

const OrderForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation = useMutation(editOrder);

  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getOrderDetail(id)));
  }

  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={`${data?.orderData?.delivery.firstName} ${data?.orderData?.delivery.lastName}'s Order`}
      >
        <Form
          onSubmit={(values) => mutation.mutate(values as OrderInterface)}
          initialValues={data}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="wrapper-half-orders">
                <div>
                  <Card title="Delivery Details" style={{ margin: "5px" }}>
                    <Field
                      label="Street1"
                      name="orderData.delivery.streetAddress"
                      columns={2}
                      style={{ width: "100%" }}
                      component={AInput}
                      hasFeedback
                      validate={Required}
                    />
                    <Field
                      label="City"
                      name="orderData.delivery.city"
                      columns={2}
                      style={{ width: "100%" }}
                      component={AInput}
                      hasFeedback
                      validate={Required}
                    />
                    <Field
                      label="State"
                      name="orderData.delivery.state"
                      columns={2}
                      style={{ width: "100%" }}
                      component={AInput}
                      hasFeedback
                      validate={Required}
                    />
                    <Field
                      label="Zip"
                      name="orderData.delivery.zip"
                      columns={2}
                      style={{ width: "100%" }}
                      component={AInput}
                      hasFeedback
                      validate={Required}
                    />
                    <Field
                      label="Email"
                      name="orderData.delivery.email"
                      columns={2}
                      style={{ width: "100%" }}
                      component={AInput}
                      hasFeedback
                      validate={Required}
                    />
                    <Field
                      label="First Name"
                      name="orderData.delivery.firstName"
                      columns={2}
                      style={{ width: "100%" }}
                      component={AInput}
                      hasFeedback
                      validate={Required}
                    />
                    <Field
                      label="Last Name"
                      name="orderData.delivery.lastName"
                      columns={2}
                      style={{ width: "100%" }}
                      component={AInput}
                      hasFeedback
                      validate={Required}
                    />
                    <Field
                      label="Phone number"
                      name="orderData.delivery.phone"
                      columns={2}
                      style={{ width: "100%" }}
                      component={AInput}
                      hasFeedback
                      validate={Required}
                    />
                    <Field
                      label="Notes"
                      name="orderData.delivery.note"
                      columns={2}
                      style={{ width: "100%" }}
                      component={ATextarea}
                      hasFeedback
                      validate={Required}
                    />
                    <div className="wrapper-two-column">
                      <div />
                      <div>
                        <SubmitButton />
                      </div>
                    </div>
                  </Card>
                </div>
                <div>
                  <OrderInvoiceDescription data={values} />
                  {values["orderData"]?.orderData.map(
                    (item: any, index: number) => {
                      return (
                        <OrderDetailCanvasData
                          key={index}
                          order={item}
                          orderObject={values}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default OrderForm;
