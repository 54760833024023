import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import OrderMainList from "./order.main.component";
import OrderForm from "./order.form.component";

const Order = (): ReactElement => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/new`} component={OrderMainList} />
      <Route exact path={`${url}/failed`} component={OrderMainList} />
      <Route exact path={`${url}/printed`} component={OrderMainList} />
      <Route exact path={`${url}/posted`} component={OrderMainList} />
      <Route exact path={`${url}/new/:id`} component={OrderForm} />
      <Route exact path={`${url}/failed/:id`} component={OrderForm} />
      <Route exact path={`${url}/printed/:id`} component={OrderForm} />
      <Route exact path={`${url}/posted/:id`} component={OrderForm} />
    </Switch>
  );
};

export default Order;
