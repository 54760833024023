import { Space, Table } from "antd";
import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { tableColumns } from "../../lib/helpers.table";
import { approveWholesaler, getWholesalerList } from "./wholesaler.thunks";
import { WholesalerInterface } from "./wholesaler.interface";
import { defaultDateFormat } from "../../lib/date";
import Button from "antd/lib/button/button";

const WholesalerList = (): ReactElement => {
  const moduleName = "articles"; // we will use this as both the query name and the delete API endpoint
  const [page, setCurrentPage] = React.useState(0);

  const { data, refetch } = useQuery(
    [moduleName, { page }],
    () => getWholesalerList({ page }),
    { keepPreviousData: true }
  );

  const mutation = useMutation(
    (data: WholesalerInterface) => approveWholesaler(data),
    {
      onSuccess: () => refetch(),
    }
  );

  const tableData = data ? data.docs : [];

  const pagination = {
    current: data?.page,
    pageSize: data?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
    total: data?.totalDocs,
    onChange: (page) => {
      pagination.current = page; // updates table pagination
      setCurrentPage(page); // re-fetch the api
    },
  };

  const columnData = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => {
        return a.firstName.localeCompare(b.firstName);
      },
      render: (text, doc) => (
        <Space size="middle">
          {doc?.firstName}
          {doc?.lastName}
        </Space>
      ),
    },
    {
      title: "Business",
      dataIndex: "businessName",
      sorter: (a, b) => {
        return a.businessName.localeCompare(b.businessName);
      },
    },
    {
      title: "ABN",
      dataIndex: "abn",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Date applied",
      dataIndex: "createdAt",
      sorter: (a, b) => {
        return a.createdAt.localeCompare(b.createdAt);
      },
      render: (text) => defaultDateFormat(text),
    },
    {
      title: "Status",
      dataIndex: "isApproved",
      sorter: (a, b) => {
        return a.isApproved.toString().localeCompare(b.isApproved.toString());
      },
      render: (text, doc) => (
        <Space size="middle">
          {doc?.isApproved ? (
            "Approved"
          ) : (
            <Button
              type="primary"
              onClick={() => mutation.mutate(doc as WholesalerInterface)}
            >
              Not Yet Approved
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const columns = tableColumns(columnData, {
    field: "Title",
    url: `${moduleName}/`,
  });
  return (
    <>
      <DashboardMaster title="Wholesalers" showAdd={false}>
        <Table
          rowKey={(doc: WholesalerInterface) => doc._id}
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </DashboardMaster>
    </>
  );
};

export default WholesalerList;
