import React, { ReactElement } from "react";
import { Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import { Link, useLocation } from "react-router-dom";
import Card from "antd/lib/card";
import OrderList from "./order.list.component";
import PageHeader from "antd/lib/page-header";
import Row from "antd/lib/grid/row";
import Button from "antd/lib/button/button";
import { DownloadOutlined } from "@ant-design/icons/lib";
import { downloadFile } from "./order.thunks";

const { TabPane } = Tabs;
const OrderMainList = (): ReactElement => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[3];
  const tabs = [
    {
      index: 1,
      path: "new",
      tabName: <Link to={"new"}>New Order</Link>,
      component: <OrderList />,
    },
    {
      index: 2,
      path: "failed",
      tabName: <Link to={"failed"}>New and Failed Order</Link>,
      component: <OrderList />,
    },
    {
      index: 3,
      path: "printed",
      tabName: <Link to={"printed"}>Printed Order</Link>,
      component: <OrderList />,
    },
    {
      index: 4,
      path: "posted",
      tabName: <Link to={"posted"}>Posted Order</Link>,
      component: <OrderList />,
    },
  ];

  const data = tabs.find((x) => x.path === currentPath);

  const download = async () => {
    const data = await downloadFile();
    data.text().then((x) => {
      const element = document.createElement("a");
      const file = new Blob([x], {
        type: "text/csv",
      });
      element.href = URL.createObjectURL(file);
      element.download = "stella-order-new.csv";
      document.body.appendChild(element);
      element.click();
    });
  };

  return (
    <>
      <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
        <div className="site-layout-background">
          <Row align="middle" justify="space-between" gutter={16}>
            <PageHeader className="site-page-header" title={"Orders"} />
            <PageHeader className="site-page-header">
              <Button
                onClick={download}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              >
                Download New Orders
              </Button>
            </PageHeader>
          </Row>
        </div>
        <Card>
          <Tabs defaultActiveKey="1" activeKey={`${data.index}`}>
            {tabs.map((data: any, index: number) => {
              return (
                <TabPane tab={data.tabName} key={index + 1}>
                  {data.component}
                </TabPane>
              );
            })}
          </Tabs>
        </Card>
      </Content>
    </>
  );
};

export default OrderMainList;
