import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import MediaForm from "./media.form.component";
import MediaList from "./media.list.component";

const Media = (): ReactElement => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/:id`} component={MediaForm} />
      <Route exact path={path} component={MediaList} />
    </Switch>
  );
};

export default Media;
