import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import WholesalerList from "./wholesaler.list.component";

const Wholesaler = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={WholesalerList} />
    </Switch>
  );
};

export default Wholesaler;
