import { fetchRequest } from "../../request/request";
import {
  SubscriberInterface,
  SubscriberPaginationInterface,
} from "./subscriber.interface";

const url = "/subscribers/";

export const getSubscriberList = async (data: {
  page: number;
}): Promise<SubscriberPaginationInterface> => {
  return await fetchRequest(url + "page", data, "post", false);
};

export const getSubscriber = async (
  id: string
): Promise<SubscriberInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const editSubscriber = async (
  data: SubscriberInterface
): Promise<void> => {
  await fetchRequest(url + data._id, data, "put");
};

export const saveSubscriber = async (
  data: SubscriberInterface
): Promise<void> => {
  await fetchRequest(url, data, "post");
};
