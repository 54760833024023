import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import PanelsForm from "./panel.form.component";
import PanelList from "./panel.list.component";

const Panel = (): ReactElement => {
  const { path, url } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${url}/:id`} component={PanelsForm} />
        <Route exact path={path} component={PanelList} />
      </Switch>
    </>
  );
};

export default Panel;
