import Button from "antd/lib/button/button";
import Result from "antd/lib/result";
import React, { ReactElement } from "react";

const notFound404 = (): ReactElement => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Button href="/" type="primary">
        Back Home
      </Button>
    }
  />
);

export default notFound404;
