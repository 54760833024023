import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { useParams } from "react-router";
import { AInput, ATextarea, AUpload } from "../../form/final-form-antd";
import { Field, Form } from "react-final-form";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import { editGallery, getGallery, saveGallery } from "./gallery.thunks";
import { GalleryInterface } from "./gallery.interface";
import { FormImagePreview } from "../../global-components/form-image-preview";

const GalleryForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation =
    id !== "new" ? useMutation(editGallery) : useMutation(saveGallery);
  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getGallery(id)));
  }
  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={
          id !== "new" ? "Edit featured gallery" : "Create new featured gallery"
        }
      >
        <Form
          onSubmit={(values) => mutation.mutate(values as GalleryInterface)}
          initialValues={data}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                label="Name"
                name="url"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Description"
                name="description"
                columns={1}
                component={ATextarea}
                hasFeedback
                rows={4}
                validate={Required}
              />
              <Field
                label="Image"
                name="image"
                columns={1}
                component={AUpload}
                hasFeedback
                validate={Required}
              />
              {values?.image || data?.localUrl ? (
                <FormImagePreview
                  url={id !== "new" ? data?.localUrl : values?.image?.url}
                />
              ) : null}
              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default GalleryForm;
