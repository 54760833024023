import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import SubscriberList from "./subscriber.list.component";
import SubscriberForm from "./subscriber.form.component";

const Subscriber = (): ReactElement => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/:id`} component={SubscriberForm} />
      <Route exact path={path} component={SubscriberList} />
    </Switch>
  );
};

export default Subscriber;
