import { Input, Select, Space } from "antd";
import React, { ReactElement, useState } from "react";
import { OrderInterface } from "../order.interface";
import Button from "antd/lib/button/button";
import {
  CheckOutlined,
  CloseOutlined,
  ScheduleOutlined,
} from "@ant-design/icons/lib";
import { useMutation } from "react-query";
import { editOrder } from "../order.thunks";
import Text from "antd/es/typography/Text";

const { Option } = Select;

export const DeliveryTracking = (props: {
  data: OrderInterface;
}): ReactElement => {
  const [tracking, setTracking] = useState(props.data.tracking);
  const [courier, setCourier] = useState(props.data.courier);
  const [isUpdate, setUpdate] = useState(false);
  const mutation = useMutation(editOrder);

  const courierItem: Array<{ name: string; value: string }> = [
    {
      name: "First Choice Couriers",
      value: "firstChoiceCouriers",
    },
    {
      name: "Auspost",
      value: "auspost",
    },
    {
      name: "DHL",
      value: "dhl",
    },
  ];

  const deliveryDateUpdateClick = () => {
    const params = {
      ...props.data,
      tracking,
      courier,
    };
    setUpdate(false);
    mutation.mutate(params);
  };

  return (
    <Space size="middle" direction="vertical">
      {props.data.delivery && !isUpdate ? (
        <Space size="middle" direction="vertical">
          <Text italic>Courier: {courier}</Text>
          <Text italic>
            Tracking:{" "}
            {props.data.orderData?.order?.pickup ? "Pick Up" : tracking || "--"}
          </Text>
        </Space>
      ) : null}

      {isUpdate ? (
        <div>
          <Select
            defaultValue={courier}
            onChange={(data) => setCourier(data)}
            style={{ width: "100%", marginBottom: 20 }}
          >
            {courierItem.map((x) => {
              return (
                <Option key={x.value} value={x.value}>
                  {x.name}
                </Option>
              );
            })}
          </Select>
          <Input
            defaultValue={tracking}
            placeholder="Tracking #"
            onChange={(data) => {
              setTracking(data.target.value);
            }}
          />
        </div>
      ) : null}
      <Space size="middle" hidden={isUpdate}>
        <Button
          type="primary"
          icon={<ScheduleOutlined />}
          onClick={() => setUpdate(true)}
        >
          Update Tracking
        </Button>
      </Space>

      <Space size="middle" hidden={!isUpdate}>
        <Button
          type="dashed"
          icon={<CheckOutlined />}
          onClick={deliveryDateUpdateClick}
        >
          Update
        </Button>
        <Button
          danger
          type="dashed"
          icon={<CloseOutlined />}
          onClick={() => setUpdate(false)}
        >
          Cancel
        </Button>
      </Space>
    </Space>
  );
};
