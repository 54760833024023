import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { useParams } from "react-router";
import { editMedia, getMedia, saveMedia } from "./media.thunks";
import { AInput, AUpload } from "../../form/final-form-antd";
import { Field, Form } from "react-final-form";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import { MediaInterface } from "./media.interface";
import { FormImagePreview } from "../../global-components/form-image-preview";

const MediaForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation =
    id !== "new" ? useMutation(editMedia) : useMutation(saveMedia);
  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getMedia(id)));
  }
  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={id !== "new" ? "Edit media" : "Create new media"}
      >
        <Form
          onSubmit={(values) => mutation.mutate(values as MediaInterface)}
          initialValues={data}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                label="Caption Title"
                name="caption.title"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Caption Sub Title"
                name="caption.subtitle"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Caption Color"
                name="caption.color"
                columns={1}
                placeholder="#FFFFFF"
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Button Label"
                name="button.label"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Button Link"
                name="button.link"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Button Color"
                name="button.color"
                columns={1}
                component={AInput}
                hasFeedback
                validate={Required}
              />
              <Field
                label="Image"
                name="image"
                columns={1}
                component={AUpload}
                hasFeedback
                validate={Required}
              />
              {values?.image || data?.image?.url ? (
                <FormImagePreview
                  url={id !== "new" ? data?.image?.url : values?.image?.url}
                />
              ) : null}
              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default MediaForm;
