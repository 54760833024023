import { Table } from "antd";
import { useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { getCategoryList } from "./category.thunks";
import { tableColumns } from "../../lib/helpers.table";
import { CategoryInterface } from "./category.interface";

const CategoryList = (): ReactElement => {
  const moduleName = "categories"; // we will use this as both the query name and the delete API endpoint
  const [page, setCurrentPage] = React.useState(0);

  const { data } = useQuery(
    [moduleName, { page }],
    () => getCategoryList({ page }),
    { keepPreviousData: true }
  );

  const tableData = data ? data.docs : [];

  const pagination = {
    current: data?.page,
    pageSize: data?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
    total: data?.totalDocs,
    onChange: (page) => {
      pagination.current = page; // updates table pagination
      setCurrentPage(page); // re-fetch the api
    },
  };

  const columnData = ["Name"];

  const columns = tableColumns(
    columnData,
    { field: "Name", url: `${moduleName}/` },
    { url: moduleName }
  );
  return (
    <>
      <DashboardMaster
        showAdd={true}
        title="Categories"
        addUrl={`/dashboard/${moduleName}/new`}
      >
        <Table
          rowKey={(doc: CategoryInterface) => doc._id}
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </DashboardMaster>
    </>
  );
};

export default CategoryList;
