import React, { ReactElement } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table/interface";
import ConfirmDelete from "../global-components/confim-delete.component";

interface DeleteDataInterface {
  url: string;
}

export function stringSort(field) {
  return (a, b): number =>
    a[field].toString().localeCompare(b[field].toString());
}

export function renderCell(
  field: any,
  value: string,
  linkableField: any,
  doc
): string | ReactElement {
  const isLinkable = field === linkableField.field;
  if (isLinkable) {
    return (
      <>
        <Link to={linkableField.url + doc._id}>{value}</Link>
      </>
    );
  }
  return value;
}

/**
 * Generate a antd column based on the data
 * @param linkableField - field with link to edit page {url:"/birth" , field:"name"}
 * @param arrColumns
 * @return {null|{sorter: function(*, *): number, dataIndex: string, fixed: string, title: *, key: string}[]}
 * @example
 ``
 const columnData = [
 "Key",
 {
      title: "Full Name",
      dataIndex: "name",
      sorter: null,
      render: (a) => <>{a}</>,
    },
 "Age",
 "Address",
 ];

 ``
 *
 */

export function tableColumns(
  arrColumns: any,
  linkableField: { field: string; url?: string } = { url: "", field: "" },
  deleteData: DeleteDataInterface = null
): ColumnsType {
  if (deleteData) {
    arrColumns.push({
      key: "delete",
      dataIndex: "_id",
      fixed: "left",
      sorter: false,
      render: (_id) => (
        <>
          <ConfirmDelete deleteLink={deleteData.url} id={_id} />
        </>
      ),
    });
  }

  return arrColumns.map((field: any) => {
    const title = typeof field === "string" ? field : field.title;
    const snakeCased = _.camelCase(title?.toString());
    const dataIndex = typeof field === "string" ? snakeCased : field.dataIndex;
    const sorter =
      typeof field === "object" &&
      Object.prototype.hasOwnProperty.call(field, "sorter")
        ? field.sorter
        : stringSort(snakeCased);
    const render =
      typeof field === "object" &&
      Object.prototype.hasOwnProperty.call(field, "render")
        ? field.render
        : (value, doc): string | ReactElement =>
            renderCell(field, value, linkableField, doc);
    return {
      title,
      key: snakeCased,
      dataIndex,
      fixed: "left",
      sorter,
      render,
    };
  });
}

export function camelToTitle(text: string): string {
  const result = text.replace(/([A-Z]{1,})/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
