import { fetchRequest } from "../../request/request";
import {
  DiscountInterface,
  DiscountPaginationInterface,
} from "./discount.interface";

const url = "/discounts/";

export const saveDiscount = async (data: DiscountInterface): Promise<void> => {
  await fetchRequest(url, data, "post", true);
};

export const editDiscount = async (data: DiscountInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put", true);
};

export const getDiscount = async (id: string): Promise<DiscountInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const getDiscountsList = async (data: {
  page: number;
}): Promise<DiscountPaginationInterface> => {
  return await fetchRequest(url + "page", data, "post", false);
};
