import Menu from "antd/lib/menu";
import React, { ReactElement } from "react";
import {
  AlignLeftOutlined,
  ApartmentOutlined,
  AreaChartOutlined,
  DiffOutlined,
  FileImageOutlined,
  LikeOutlined,
  PictureOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  StarOutlined,
  TableOutlined,
  TagOutlined,
  TeamOutlined,
} from "@ant-design/icons/lib";
import { useLocation } from "react-router-dom";

interface MenuInterface {
  path: string;
  label: string;
  icon?: React.ReactNode;
}

export const DashboardSidebar = ({
  handleClick,
  isCollapse = false,
}: {
  handleClick: (string) => void;
  isCollapse: boolean;
}): ReactElement => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];

  const menuItems: Array<MenuInterface> = [
    {
      path: "home",
      label: "Dashboard",
      icon: <AreaChartOutlined />,
    },
    {
      path: "articles",
      label: "Articles",
      icon: <AlignLeftOutlined />,
    },
    {
      path: "canvas",
      label: "Canvas",
      icon: <FileImageOutlined />,
    },
    {
      path: "categories",
      label: "Categories",
      icon: <ApartmentOutlined />,
    },
    {
      path: "discounts",
      label: "Discounts",
      icon: <TagOutlined />,
    },
    {
      path: "globalsettings",
      label: "Global Settings",
      icon: <SettingOutlined />,
    },
    {
      path: "panels",
      label: "Panels",
      icon: <TableOutlined />,
    },
    {
      path: "medias",
      label: "Medias",
      icon: <PictureOutlined />,
    },
    {
      path: "galleries",
      label: "Featured Gallery",
      icon: <FileImageOutlined />,
    },
    {
      path: "orders/new",
      label: "Orders",
      icon: <ShoppingCartOutlined />,
    },
    {
      path: "pages",
      label: "Pages",
      icon: <DiffOutlined />,
    },
    // {
    //   path: "shippings",
    //   label: "Shipping Options",
    //   icon: <TagsOutlined />,
    // },
    // {
    //   path: "states",
    //   label: "States",
    //   icon: <HomeOutlined />,
    // },
    {
      path: "subscribers",
      label: "Subscribes",
      icon: <LikeOutlined />,
    },
    {
      path: "vouchers",
      label: "Vouchers",
      icon: <StarOutlined />,
    },
    {
      path: "wholesalers",
      label: "Wholesalers",
      icon: <TeamOutlined />,
    },
  ];

  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[currentPath]}
        inlineCollapsed={isCollapse}
        onSelect={(x) => handleClick(x.key)}
        items={menuItems.map((x: MenuInterface) => {
          return {
            label: x.label,
            key: x.path,
            icon: x.icon,
          };
        })}
      />
    </>
  );
};
