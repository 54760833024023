import { Content } from "antd/es/layout/layout";
import React, { ReactElement } from "react";
import { PlusSquareOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ErrorAlert from "../../global-components/error-alert.component";
import PageHeader from "antd/lib/page-header";
import Button from "antd/lib/button/button";
import Card from "antd/lib/card";
import Row from "antd/lib/grid/row";
import { DownloadOutlined } from "@ant-design/icons/lib";
import { getApiUrl, getToken } from "../../lib/helpers.common";

export interface DashboardMasterInterface {
  showAdd?: boolean;
  title: string;
  addUrl?: string;
  children?: ReactElement;
  showExport?: boolean;
  exportFileName?: string;
  exportLink?: string;
}

const exportFile = async (link: string): Promise<Response> => {
  const token = getToken();
  return fetch(getApiUrl() + link, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: null,
  });
};

const download = async (fileName: string, link: string) => {
  const data = await exportFile(link);
  data.text().then((x) => {
    const element = document.createElement("a");
    const file = new Blob([x], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.csv`;
    document.body.appendChild(element);
    element.click();
  });
};

const DashboardMaster = ({
  showAdd,
  title,
  addUrl,
  children,
  showExport,
  exportFileName,
  exportLink,
}: Partial<DashboardMasterInterface>): ReactElement => (
  <>
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <div className="site-layout-background">
        <Row align="middle" justify="space-between" gutter={16}>
          <PageHeader className="site-page-header" title={title} />
          <PageHeader className="site-page-header">
            {showExport === true && (
              <Button
                type="primary"
                shape="round"
                onClick={() => download(exportFileName, exportLink)}
                icon={<DownloadOutlined />}
              >
                Export
              </Button>
            )}
            {showAdd !== false && (
              <Link to={addUrl} style={{ marginLeft: "10px" }}>
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlusSquareOutlined />}
                >
                  Add New
                </Button>
              </Link>
            )}
          </PageHeader>
        </Row>
      </div>
      <Card>
        <ErrorAlert />
        {children}
      </Card>
    </Content>
  </>
);

export default DashboardMaster;
