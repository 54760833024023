import React, { ReactElement, useState } from "react";
import { useQuery } from "react-query";
import { getStatistics } from "./home.thunks";
import { Line } from "@ant-design/plots";
import { Content } from "antd/es/layout/layout";
import Row from "antd/lib/grid/row";
import PageHeader from "antd/lib/page-header";
import Card from "antd/lib/card";
import { Col, DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import { Pie } from "@ant-design/charts";

const Home = (): ReactElement => {
  const moduleName = "home";
  const [year, setYear] = useState(moment());

  const { data: response } = useQuery(
    [moduleName, { year }],
    () => getStatistics({ year }),
    { keepPreviousData: true }
  );

  const lineGraph = response ? response.lineGraph : [];
  const pieChart = response ? response.pieChart : [];

  const lineChartConfig = {
    data: lineGraph,
    xField: "month",
    yField: "totalPrice",
    xAxis: {
      tickCount: 12,
    },
    point: {
      size: 3,
      shape: "circle",
      style: {
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
  };

  const pieConfig = {
    appendPadding: 10,
    data: pieChart,
    angleField: "value",
    colorField: "type",
    radius: 0.7,
    label: {
      type: "spider",
      labelHeight: 5,
      content: "{percentage}",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };

  const onChange: DatePickerProps["onChange"] = (date) => {
    setYear(date);
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <div className="site-layout-background">
        <Row align="middle" justify="space-between" gutter={16}>
          <PageHeader className="site-page-header" title={"Statistics"} />
          <PageHeader className="site-page-header">
            <DatePicker defaultValue={year} onChange={onChange} picker="year" />
          </PageHeader>
        </Row>
      </div>
      <Card>
        <Row gutter={16}>
          <Col span={16}>
            <Line {...lineChartConfig} />
          </Col>
          <Col span={8}>
            <Pie {...pieConfig} />
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default Home;
