import { fetchRequest } from "../../request/request";
import { setToken } from "./user.actions";
import { store } from "../../index";

export const signin = async (values) => {
  const response = await fetchRequest("/login", values, "post", false);
  if (response) {
    store.dispatch(setToken(response));
    // 'persist:root' loads the initial state which is null after route redirect
    localStorage.setItem("persist:root", response);
  }
};

export const register = async (values) => {
  return fetchRequest(
    "/register",
    values,
    "post",
    true,
    `Success! Please check your email (${values.email}) for verification.`
  );
};
