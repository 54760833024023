/**
 * AntD element + React Final Form
 * Any additional form elements must be added here
 */
import React, { ReactElement } from "react";
import moment, { Moment } from "moment";
import { getApiUrl, getToken, padString } from "../lib/helpers.common";
import Form from "antd/lib/form";
import Radio from "antd/lib/radio";
import DatePicker from "antd/lib/date-picker";
import message from "antd/lib/message";
import Button from "antd/lib/button/button";
import Upload from "antd/lib/upload/Upload";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import Select from "antd/lib/select";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Switch from "antd/lib/switch";
import InputNumber from "antd/lib/input-number";
import Input from "antd/lib/input";
import JoditEditor from "jodit-react";

const FormItem = Form.Item;
const { TextArea, Password } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

/* eslint-disable react/display-name */
const makeField =
  (Component) =>
  ({
    input,
    meta,
    children,
    hasFeedback,
    label,
    columns,
    ...rest
  }: {
    input: any;
    meta: any;
    children: any;
    hasFeedback: any;
    label: string;
    columns: number;
  }) => {
    const hasError = meta.touched && meta.invalid;
    const wrapper = columns === 1 ? "wrapper-one-column" : "wrapper";
    return (
      <div className={wrapper}>
        <div className="form-item-label">
          <label>{label} :</label>
        </div>
        <div>
          <FormItem
            validateStatus={hasError ? "error" : "success"}
            hasFeedback={hasFeedback && hasError}
            help={hasError && meta.error}
          >
            <Component {...input} {...rest} placeholder={label}>
              {children}
            </Component>
          </FormItem>
        </div>
      </div>
    );
  };

export const CustomDatepicker = ({
  value,
  onChange,
}: {
  value: any;
  onChange?: (date: Moment) => void;
}) => (
  <DatePicker
    className="w-100"
    onChange={(dt) => onChange(moment(dt))}
    value={moment(value)}
  />
);

export const CustomSwitch = (props: {
  value: string;
  checkLabel?: React.ReactNode;
  unCheckLabel?: React.ReactNode;
  onChange?: (checked: boolean, event?: MouseEvent) => void;
}): ReactElement => {
  const { value } = props;
  const checkLabel = props.checkLabel;
  const unCheckLabel = props.unCheckLabel;
  return (
    <Switch
      checked={!!value}
      checkedChildren={checkLabel}
      unCheckedChildren={unCheckLabel}
      onChange={(checked) => props.onChange(checked)}
    />
  );
};

export const CustomRangeDatepicker = (props: {
  value: string | any;
}): ReactElement => {
  const prop = props;
  const { value } = props;
  const momentValue: Array<Moment> =
    value && value.length === 2
      ? [moment(prop?.value[0]), moment(prop?.value[1])]
      : [moment(), moment()];

  return (
    <RangePicker
      className="w-100"
      onChange={(d1, d2) => [d1, d2]}
      value={[momentValue[0], momentValue[1]]}
    />
  );
};

export const CustomSelect = (props: {
  value: string;
  options?: Array<{ _id: string; name: string }>;
  onChange?: (value: string) => void;
}): ReactElement => {
  const { value } = props;
  const options = props?.options;
  return (
    <Select onChange={(value) => props.onChange(value)} value={value}>
      {options?.map((sl) => (
        <Option key={sl._id} value={sl._id}>
          {sl.name}
        </Option>
      ))}
    </Select>
  );
};

export const CustomUploader = (input): ReactElement => {
  const props = {
    maxCount: 1,
    name: "photo",
    action: `${getApiUrl()}/upload`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    onChange: (info): void => {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        input.onChange(info.file.response);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        input.onChange(null);
      }
    },
  };

  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
};

export const CustomWYSIWYGEditor = (props: {
  value: string;
  onChange?: (value: string) => void;
}): ReactElement => {
  const { value } = props;
  return (
    <JoditEditor
      ref={null}
      value={value}
      onChange={(newContent) => props.onChange(newContent)}
    />
  );
};

export const PostalInput = (props: {
  value: number;
  onChange?: (value: number) => void;
}): ReactElement => {
  const { value } = props;
  return (
    <InputNumber
      value={value}
      min={100}
      max={9999}
      formatter={(value) => `${padString(value, 4)}`}
      onChange={props.onChange}
    />
  );
};

export const InputPercentage = (props: {
  value: number;
  onChange?: (value: number) => void;
}): ReactElement => {
  const { value } = props;
  return (
    <div>
      <InputNumber
        defaultValue={value}
        min={0}
        max={99999}
        formatter={(value) => `${value}`}
        onChange={props.onChange}
      />{" "}
      %
    </div>
  );
};

export const AWYSIWYGEditor = makeField(CustomWYSIWYGEditor);
export const AInput = makeField(Input);
export const ARadioGroup = makeField(Radio.Group);
export const ARadio = makeField(Radio);
export const ASelect = makeField(CustomSelect);
export const APassword = makeField(Password);
export const ACheckbox = makeField(Checkbox);
export const ATextarea = makeField(TextArea);
export const AUpload = makeField(CustomUploader);
export const ARangePicker = makeField(CustomRangeDatepicker);
export const ADatePicker = makeField(CustomDatepicker);
export const ASwitch = makeField(CustomSwitch);
export const AInputNumber = makeField(InputNumber);
export const APostalInput = makeField(PostalInput);
export const AInputPercentage = makeField(InputPercentage);
