import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import GlobalSettingsForm from "./globalsettings.form.component";

const GlobalSettings = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={GlobalSettingsForm} />
    </Switch>
  );
};

export default GlobalSettings;
