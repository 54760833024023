import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

const Auth = (props): ReactElement => {
  const { isLoggedIn } = useSelector((state: any) => state.userReducers);

  if (isLoggedIn) {
    return <Redirect to="/dashboard/home" />;
  }

  return <Route {...props} />;
};

export default Auth;
