import { SETTINGS_GET } from "./settings.actions";

const initialState = { settings: null, isSaved: false };

const settingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SETTINGS_GET: {
      const { settings } = payload;
      return {
        ...state,
        ...initialState,
        settings,
      };
    }

    default:
      return state;
  }
};

export default settingsReducer;
