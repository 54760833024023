import { Content } from "antd/es/layout/layout";
import { Route } from "react-router";
import React, { ReactElement } from "react";
import ErrorAlert from "../../global-components/error-alert.component";

interface OrderDashboardMasterInterface {
  children: ReactElement;
}

const OrderCustomDashboardComponent = ({
  ...props
}: Partial<OrderDashboardMasterInterface>): ReactElement => (
  <>
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <ErrorAlert />
      <Route {...props} />
    </Content>
  </>
);

export default OrderCustomDashboardComponent;
