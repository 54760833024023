export function getUserData(): any {
  const data = localStorage.getItem("persist:root");
  const userData = JSON.parse(data);
  return JSON.parse(userData?.userReducers);
}

export function getToken(): string {
  const userInfo = getUserData();
  return userInfo && userInfo.userData ? userInfo.userData.token : "";
}

export function getApiUrl(): string {
  return process.env.REACT_APP_API;
}

export const padString = (s, length = 2) => {
  while (s.toString().length < length) {
    s = "0" + s;
  }
  return s;
};
