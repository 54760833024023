import { PayloadAction } from "@reduxjs/toolkit";
import {
  CLOSE_REQUEST_ERROR,
  DELETE_SUCCESS,
  REQUEST_FAILURE,
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
  SAVE_SUCCESS,
  VALIDATION_ERROR,
} from "./request.actions";
import { RequestInterface } from "../interfaces/request.interface";

const defaultRequestState: RequestInterface = {
  isFailure: false,
  isLoading: false,
  isValidationError: false,
  isClose: true,
  saveSuccess: false,
  deletedSuccess: false,
};

const initialState: RequestInterface = {
  title: null,
  message: null,
  ...defaultRequestState,
};

/**
 * request object in state, make sure to add this in store.js combineReducers
 * @param state Redux State
 * @param action Redux Action
 * @return {{isLoading: boolean, isFailure: boolean}|{isLoading: boolean, message, isFailure: boolean}}
 */
const requestReducers = (
  state = initialState,
  action: PayloadAction<RequestInterface>
): any => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_FAILURE: {
      const { message, title } = payload;
      return {
        ...state,
        ...defaultRequestState,
        isFailure: true,
        isClose: false,
        message,
        title,
      };
    }
    case CLOSE_REQUEST_ERROR: // ??
      return {
        ...state,
        ...defaultRequestState,
      };

    case REQUEST_SUCCESS:
      return {
        ...state,
        ...defaultRequestState,
      };
    case REQUEST_IN_PROGRESS:
      return {
        ...state,
        ...defaultRequestState,
        isLoading: true,
      };

    case VALIDATION_ERROR:
      return {
        ...state,
        ...defaultRequestState,
        isValidationError: true,
        message: payload.message,
        title: payload.title,
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        ...defaultRequestState,
        saveSuccess: true,
        message: payload.message,
      };

    case DELETE_SUCCESS:
      return {
        ...state,
        ...defaultRequestState,
        deletedSuccess: true,
      };
    default:
      return state;
  }
};

export default requestReducers;
