import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import PageForm from "./page.form.component";
import PagesList from "./page.list.component";

const Page = (): ReactElement => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}/:id`} component={PageForm} />
      <Route exact path={path} component={PagesList} />
    </Switch>
  );
};

export default Page;
