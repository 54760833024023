import { fetchRequest } from "../../request/request";
import { GlobalSettingsInterface } from "./globalsettings.interface";

const url = "/globalsettings/";

export const getGlobalSetting = async (): Promise<GlobalSettingsInterface> => {
  return fetchRequest(url, null, "get", false);
};

export const saveGlobalSetting = async (
  data: GlobalSettingsInterface
): Promise<void> => {
  await fetchRequest(url, data, "put", true, "Settings Save");
};
