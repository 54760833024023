import React from "react";
import { Card, Col, Form, Input } from "antd";
import { SignupRow } from "./user.styled.components";
import ErrorAlert from "../../global-components/error-alert.component";
import SubmitButton from "../../global-components/submit-button.component";
import Auth from "../../global-components/auth.component";
import { useMutation } from "react-query";
import { signin } from "./user.thunks";

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

export const Login = () => {
  const mutation = useMutation(signin);

  return (
    <Auth>
      <SignupRow>
        <Col span={8} offset={8}>
          <div className="site-card-border-less-wrapper">
            <Card title="Stella Canvas User's login">
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={(values) => {
                  mutation.mutate(values);
                }}
              >
                <ErrorAlert />
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <SubmitButton />
                </Form.Item>
                {/*<Form.Item {...tailLayout}>*/}
                {/*  <p>*/}
                {/*    <FacebookLogin/>*/}
                {/*  </p>*/}
                {/*</Form.Item>*/}
              </Form>
            </Card>
          </div>
        </Col>
      </SignupRow>
    </Auth>
  );
};
