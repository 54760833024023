import { fetchRequest } from "../../request/request";
import {
  CategoryInterface,
  CategoryPaginationInterface,
} from "./category.interface";

const url = "/categories/";

export const getCategoryList = async (data: {
  page: number;
}): Promise<CategoryPaginationInterface> => {
  return await fetchRequest(url + "page", data, "post", false);
};

export const getCategory = async (id: string): Promise<CategoryInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const editCategory = async (data: CategoryInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put", true);
};

export const saveCategory = async (data: CategoryInterface): Promise<void> => {
  await fetchRequest(url, data, "post", true);
};

export const getAllCategory = async (): Promise<Array<CategoryInterface>> => {
  return fetchRequest(url + "list", {}, "post", false);
};
