import { Button, Card, Descriptions, Tag } from "antd";
import { FilePdfOutlined } from "@ant-design/icons/lib";
import React, { ReactElement } from "react";

const apiHost = process.env.REACT_APP_API;

export const OrderInvoiceDescription = (props: { data: any }): ReactElement => {
  const { data } = props;
  return (
    <Card style={{ margin: "5px" }}>
      <Descriptions
        column={2}
        extra={
          <Button
            type="primary"
            icon={<FilePdfOutlined />}
            href={apiHost + "/api/public/order/invoice/generate/" + data.token}
            download="invoice.pdf"
          >
            Download Tax Invoice
          </Button>
        }
        title={<code>Paypal ID and Invoice ID: {data.paypalData?.id}</code>}
        bordered
        size="small"
      >
        <Descriptions.Item label="Total">${data.total}</Descriptions.Item>
        <Descriptions.Item label="Turn Around Time">
          {data.orderData?.order?.turnaround}
        </Descriptions.Item>
        <Descriptions.Item label="Pickup">
          {data.orderData?.order?.pickup ? (
            <Tag color="green">Yes</Tag>
          ) : (
            <Tag color="red">No</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Insurance">
          {data.orderData?.order?.insurance ? (
            <Tag color="green">Yes</Tag>
          ) : (
            <Tag color="red">No</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Discount Code">
          {data.orderData?.order?.discount.code || "--"}
        </Descriptions.Item>
        <Descriptions.Item label="Discount Value">
          {data.orderData?.order?.discount.value || "--"}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
