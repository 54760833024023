import { Table } from "antd";
import { useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { tableColumns } from "../../lib/helpers.table";
import { getPageList } from "./page.thunks";
import { PageInterface } from "./page.interface";
import { Link } from "react-router-dom";

const PagesList = (): ReactElement => {
  const moduleName = "pages"; // we will use this as both the query name and the delete API endpoint
  const [page, setCurrentPage] = React.useState(0);

  const { data } = useQuery(
    [moduleName, { page }],
    () => getPageList({ page }),
    { keepPreviousData: true }
  );

  const tableData = data ? data.docs : [];

  const pagination = {
    current: data?.page,
    pageSize: data?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
    total: data?.totalDocs,
    onChange: (page) => {
      pagination.current = page; // updates table pagination
      setCurrentPage(page); // re-fetch the api
    },
  };

  const columnData = [
    {
      title: "Name",
      dataIndex: "title",
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      render: (text, doc) => (
        <Link to={`${moduleName}/` + doc._id}>{doc?.title}</Link>
      ),
    },
    {
      title: "Page",
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      dataIndex: "title",
    },
  ];

  const columns = tableColumns(
    columnData,
    { field: "Name", url: `${moduleName}/` },
    { url: moduleName }
  );
  return (
    <>
      <DashboardMaster
        showAdd={true}
        title="Pages"
        addUrl={`/dashboard/${moduleName}/new`}
      >
        <Table
          rowKey={(doc: PageInterface) => doc._id}
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </DashboardMaster>
    </>
  );
};

export default PagesList;
