import Button from "antd/lib/button/button";
import Result from "antd/lib/result";
import React, { ReactElement } from "react";

const otherError = (): ReactElement => (
  <Result
    status="500"
    title="Error 500 - Error has occured."
    subTitle="Sorry, something went wrong."
    extra={
      <Button href="/" type="primary">
        Back Home
      </Button>
    }
  />
);

export default otherError;
