import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import DiscountForm from "./discount.form.component";
import DiscountList from "./discount.list.component";

const Discount = (): ReactElement => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}/:id`} component={DiscountForm} />
      <Route exact path={path} component={DiscountList} />
    </Switch>
  );
};

export default Discount;
