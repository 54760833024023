import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import ArticlesForm from "./article.form.component";
import ArticlesList from "./article.list.component";

const Articles = (): ReactElement => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}/:id`} component={ArticlesForm} />
      <Route exact path={path} component={ArticlesList} />
    </Switch>
  );
};

export default Articles;
