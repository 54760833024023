import SettingOutlined from "@ant-design/icons/SettingOutlined";
import React, { ReactElement } from "react";
import { Header } from "antd/es/layout/layout";
import { useRouter } from "../../hooks/router.hooks";
import { store } from "../../index";
import { logout } from "../users/user.actions";
import Row from "antd/lib/row";
import Menu from "antd/lib/menu";

interface MenuInterface {
  label: string;
  key: string;
  icon: ReactElement;
  children?: Array<{ label: string; key: string; icon?: ReactElement }>;
}

const TopHeader = (): ReactElement => {
  const router = useRouter();

  const handleClick = (e) => {
    if (e === "logout") {
      store.dispatch(logout());
    } else {
      router.push(`${e}`);
    }
  };

  const menu: Array<MenuInterface> = [
    {
      label: "User Settings",
      key: "submenu",
      icon: <SettingOutlined />,
      children: [
        { label: "User Settings", key: "/dashboard/settings" },
        {
          label: "Change Password",
          key: "/dashboard/settings/change-password",
        },
        { label: "Logout", key: "logout" },
      ],
    },
  ];
  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <Row justify="space-between">
        <div />
        {/* left */}
        <div />
        {/* center */}
        {/* right */}
        <Menu
          mode="horizontal"
          items={menu}
          onSelect={(x) => handleClick(x.key)}
        ></Menu>
      </Row>
    </Header>
  );
};

export default TopHeader;
