import { fetchRequest } from "../../request/request";
import { CanvasInterface, CanvasPaginationInterface } from "./canvas.interface";

const url = "/canvas/";

export const saveCanvas = async (data: CanvasInterface): Promise<void> => {
  await fetchRequest(url, data, "post", true);
};

export const editCanvas = async (data: CanvasInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put", true);
};

export const getCanvas = async (id: string): Promise<CanvasInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const getCanvasList = async (data: {
  page: number;
}): Promise<CanvasPaginationInterface> => {
  return await fetchRequest(url + "page", data, "post", false);
};
