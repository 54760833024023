import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import VoucherList from "./voucher.list.component";

const Voucher = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={VoucherList} />
    </Switch>
  );
};

export default Voucher;
