import { fetchRequest } from "../../request/request";
import { getApiUrl, getToken } from "../../lib/helpers.common";
import { OrderInterface, OrderPaginationInterface } from "./order.interface";

const url = "/orders/";

export const getOrder = async (
  data: { page: number; key: string; deliveryDate: string },
  status: string
): Promise<OrderPaginationInterface> => {
  return await fetchRequest(url + "list/" + status, data, "post", false);
};

export const getOrderDetail = async (id: string): Promise<OrderInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const editOrder = async (data: OrderInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put", true);
};

export const moveOrder = async (data: {
  ids: Array<string>;
  status: string;
}): Promise<void> => {
  return fetchRequest(
    url + "move",
    data,
    "post",
    true,
    "Order (s) have been moved to " + data.status
  );
};

export const downloadFile = async (): Promise<Response> => {
  const token = getToken();
  return fetch(getApiUrl() + url + "export/new", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: null,
  });
};
