import { useMutation, useQuery } from "react-query";
import DashboardMaster from "../dashboard/dashboard-master-layout.component";
import React, { ReactElement } from "react";
import { editPanel, getPanel, savePanel } from "./panel.thunks";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router";
import { Divider } from "antd";
import { AInputNumber } from "../../form/final-form-antd";
import { Required } from "../../form/validations";
import SubmitButton from "../../global-components/submit-button.component";
import Title from "antd/lib/typography/Title";
import { PanelInterface } from "./panel.interface";

const PanelForm = (): ReactElement => {
  const { id }: { id: string } = useParams();
  const mutation =
    id !== "new" ? useMutation(editPanel) : useMutation(savePanel);
  let data;
  if (id !== "new") {
    ({ data } = useQuery(["id", { id }], () => getPanel(id)));
  }
  return (
    <>
      <DashboardMaster
        showAdd={false}
        title={id !== "new" ? "Edit panel" : "Create new panel"}
      >
        <Form
          onSubmit={(values) => mutation.mutate(values as PanelInterface)}
          initialValues={data}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Panel Width"
                    name="size.width"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Panel Height"
                    name="size.height"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div>
                <Title level={5}>Diptych</Title>
                <Divider />
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Regular Price"
                    name="dual.regular"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Discounted Price"
                    name="dual.discounted"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Wholesale Price"
                    name="dual.wholesale"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Weight"
                    name="dual.weight"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div>
                <Title level={5}>Tripitch</Title>
                <Divider />
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Regular Price"
                    name="tri.regular"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Discounted Price"
                    name="tri.discounted"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Wholesale Price"
                    name="tri.wholesale"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Weight"
                    name="tri.weight"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>

              <div>
                <Title level={5}>Quadritych</Title>
                <Divider />
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Regular Price"
                    name="quad.regular"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Discounted Price"
                    name="quad.discounted"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Wholesale Price"
                    name="quad.wholesale"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
                <div>
                  <Field
                    label="Weight"
                    name="quad.weight"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div className="wrapper-half">
                <div>
                  <Field
                    label="Sort"
                    name="sort"
                    columns={1}
                    style={{ width: "100%" }}
                    component={AInputNumber}
                    hasFeedback
                    validate={Required}
                  />
                </div>
              </div>
              <div className="wrapper-two-column">
                <div />
                <div>
                  <SubmitButton />
                </div>
              </div>
            </form>
          )}
        />
      </DashboardMaster>
    </>
  );
};
export default PanelForm;
