import {
  closeRequestError,
  requestFailure,
  saveSuccess,
  validationError,
} from "./request.actions";
import { getApiUrl, getToken } from "../lib/helpers.common";
import { store } from "../index";
import { logout } from "../components/users/user.actions";
/*
	wrapper for all requests, automated request in progress and failure state
 */
export const fetchRequest = (
  url: string,
  data = null,
  httpMethod: string = null,
  dispatchSaveMessage: boolean = true,
  saveMessage: string = null
): Promise<any> => {
  const method = httpMethod || (!data ? "get" : "post");
  const token = getToken();

  store.dispatch(closeRequestError());
  // store.dispatch(requestInProgress());

  return fetch(getApiUrl() + url, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: data ? JSON.stringify(data) : null,
  })
    .then((response) => {
      if (response.status === 200) {
        if (dispatchSaveMessage && httpMethod != "get") {
          store.dispatch(saveSuccess(saveMessage));
        }
        return response.json();
      } else if (response.status === 422) {
        return response.json().then((a) => {
          store.dispatch(validationError(a.message));
        });
      } else if (response.status === 401) {
        store.dispatch(logout());
      } else {
        store.dispatch(requestFailure());
      }

      return Promise.reject();
    })
    .catch((err) => {
      store.dispatch(requestFailure());
      return Promise.reject(err);
    });
};
