import { fetchRequest } from "../../request/request";
import {
  WholesalerInterface,
  WholesalerPaginationInterface,
} from "./wholesaler.interface";

const url = "/wholesalers/";

export const getWholesalerList = async (data: {
  page: number;
}): Promise<WholesalerPaginationInterface> => {
  return await fetchRequest(url + "/page", data, "post", false);
};

export const approveWholesaler = async (
  data: WholesalerInterface
): Promise<void> => {
  const newData = { ...data, isApproved: true };
  await fetchRequest(url + data._id, newData, "put", true);
};
