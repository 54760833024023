import { DatePicker, Space } from "antd";
import React, { ReactElement, useState } from "react";
import { OrderInterface } from "../order.interface";
import Button from "antd/lib/button/button";
import {
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons/lib";
import { useMutation } from "react-query";
import { editOrder } from "../order.thunks";
import moment from "moment";
import Text from "antd/es/typography/Text";

export const DeliveryDateStatus = (props: {
  data: OrderInterface;
}): ReactElement => {
  const [deliveryDate, setDeliveryDate] = useState(props.data.delivery);
  const [isUpdate, setUpdate] = useState(false);
  const mutation = useMutation(editOrder);

  const deliveryDateUpdateClick = () => {
    const params = {
      ...props.data,
      delivery: deliveryDate,
    };
    setUpdate(false);
    mutation.mutate(params);
  };

  return (
    <Space size="middle" direction="vertical">
      <div>
        <Text italic>
          {deliveryDate && moment(deliveryDate).format("MMMM DD, YYYY")}
        </Text>
      </div>
      {isUpdate ? (
        <DatePicker
          defaultValue={moment(deliveryDate)}
          onChange={(date) =>
            date !== null
              ? setDeliveryDate(date.format("MM-DD-YYYY"))
              : setDeliveryDate("")
          }
        />
      ) : null}
      <Space size="middle" hidden={isUpdate}>
        <Button
          type="primary"
          icon={<CalendarOutlined />}
          onClick={() => setUpdate(true)}
        >
          Update Delivery Date
        </Button>
      </Space>

      <Space size="middle" hidden={!isUpdate}>
        <Button
          type="dashed"
          icon={<CheckOutlined />}
          onClick={deliveryDateUpdateClick}
        >
          Update
        </Button>
        <Button
          danger
          type="dashed"
          icon={<CloseOutlined />}
          onClick={() => setUpdate(false)}
        >
          Cancel
        </Button>
      </Space>
    </Space>
  );
};
