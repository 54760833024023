import Alert from "antd/lib/alert";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeRequestError } from "../request/request.actions";
import { VerticalSpace } from "./vertical-space.styled.components";

const ErrorAlert = (): ReactElement => {
  const {
    isFailure,
    isClose,
    isValidationError,
    saveSuccess,
    deletedSuccess,
    message,
    title,
  } = useSelector((state: any) => state.requestReducers);

  const dispatch = useDispatch();

  function onClose(): void {
    dispatch(closeRequestError());
  }

  return (
    <>
      {isFailure && !isClose && (
        <VerticalSpace direction="vertical">
          <Alert
            message={title}
            description={message}
            type="error"
            closable
            onClose={onClose}
          />
          &nbsp;
        </VerticalSpace>
      )}

      {isValidationError && (
        <VerticalSpace direction="vertical">
          <Alert
            message={title}
            description={message}
            type="error"
            closable
            onClose={onClose}
          />
          &nbsp;
        </VerticalSpace>
      )}

      {saveSuccess && (
        <VerticalSpace direction="vertical">
          <Alert
            message={message || "Saved"}
            showIcon
            type="success"
            closable
            onClose={onClose}
          />
          &nbsp;
        </VerticalSpace>
      )}

      {deletedSuccess && (
        <VerticalSpace direction="vertical">
          <Alert
            message="Item Deleted"
            showIcon
            type="success"
            closable
            onClose={onClose}
          />
          &nbsp;
        </VerticalSpace>
      )}
    </>
  );
};

export default ErrorAlert;
