import { fetchRequest } from "../../request/request";
import {
  ArticleInterface,
  ArticlePaginationInterface,
} from "./article.interface";

const url = "/articles/";

export const saveArticle = async (data: ArticleInterface): Promise<void> => {
  await fetchRequest(url, data, "post", true);
};

export const editArticle = async (data: ArticleInterface): Promise<void> => {
  await fetchRequest(url + data._id, data, "put", true);
};

export const getArticle = async (id: string): Promise<ArticleInterface> => {
  return fetchRequest(url + id, null, "get", false);
};

export const deleteArticle = async (id: string): Promise<void> => {
  await fetchRequest(url + id, null, "delete", true, "Successfully deleted!");
};

export const getArticleList = async (data: {
  page: number;
}): Promise<ArticlePaginationInterface> => {
  return await fetchRequest(url + "page/", data, "post", false);
};
