import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer } from "redux-persist";
import requestReducers from "./request/request.reducer";
import userReducers from "./components/users/user.reducer";
import settingsReducer from "./components/settings/settings.reducer";

const reducers = {
  requestReducers,
  userReducers,
  settingsReducer,
};

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  // // dynamic data DO NOT need to persists
  blacklist: ["requestReducers", "birthReducers", "settingsReducer"], // we only persists local actions
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = (): Store =>
  createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
