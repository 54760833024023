import { Select } from "antd";
import React, { ReactElement } from "react";

const { Option } = Select;

export const OrderMoveDropDown = ({
  handleChange,
}: {
  handleChange: (value: string) => void;
}): ReactElement => {
  const moveArray: Array<{ name: string; value: string }> = [
    {
      name: "New",
      value: "new",
    },
    {
      name: "Printed",
      value: "printed",
    },
    {
      name: "Posted",
      value: "posted",
    },
  ];

  return (
    <Select defaultValue="new" onChange={handleChange} style={{ width: 200 }}>
      {moveArray.map((x) => {
        return (
          <Option key={x.value} value={x.value}>
            {x.name}
          </Option>
        );
      })}
    </Select>
  );
};
